import { useState } from "react";
import plusImage from "../../assets/plus.svg";
import minusImage from "../../assets/minus.svg";
import "./styles.scss";

interface Props {
  title: string;
  children: React.ReactNode;
  isExpanded?: boolean;
  highlightTitle?: boolean;
}

const Accordion: React.FC<Props> = (props) => {
  const { title, children, isExpanded = false, highlightTitle = false } = props;
  const [expanded, setExpoanded] = useState(isExpanded);

  return (
    <div
      className={`accordion-container ${
        expanded ? "accordion-expanded-container" : ""
      }`}
    >
      <p
        className={`accordion-title ${
          highlightTitle ? "accordion-title-highlight" : ""
        }`}
      >
        {title}
      </p>
      <img
        src={expanded ? minusImage : plusImage}
        alt="Plus"
        className="accordion-expand-icon"
        onClick={() => {
          setExpoanded(!expanded);
        }}
      />
      {expanded && <div className="accordion-children">{children}</div>}
    </div>
  );
};

export default Accordion;
