import { FC, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Multiselect from "multiselect-react-dropdown";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../Form";
import {
  workSelector,
  userSelector,
  dealBreakersSelector,
} from "../../redux/selectors";
import { setWork } from "../../redux/actions/work";
import { workQuestions } from "../../utils/constants";
import { XANO_API_URL } from "../../utils/api-urls";
import { setDealBreakers } from "../../redux/actions/dealBreakers";
import "./styles.scss";

const Work: FC<any> = (props) => {
  const { validateForm } = props;
  const [error, setError] = useState("");
  const { work } = useSelector(workSelector);
  const { dealBreakers } = useSelector(dealBreakersSelector);

  const [industries, setIndustries] = useState<string[]>(
    work.formData && work.formData.industry ? [work.formData.industry] : []
  );
  const [industryDisabled, setIndustryDisabled] = useState(
    work.formData && work.formData.industryDisable ? true : false
  );
  const [isFormValid, setIsFormValid] = useState(work.isConfigured);
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  const [anyIndustry, setAnyIndustry] = useState(
    work.formData && work.formData.industry === "anyIndustry"
  );

  const [industryNotSure, setIndustryNotsure] = useState(
    work.formData && work.formData.industry === "notSure"
  );

  const [loading, setLoading] = useState(true);

  const [Industry, setIndustry] = useState(
    work.formData ? work.formData.Industry : false
  );
  const [industryOptions, setIndustryOptions] = useState<String[]>([]);

  const handleChange = (formData: any, isValid: boolean) => {
    setIsFormValid(isValid);
    dispatch(
      setWork({
        ...work,
        formData: { ...work.formData, ...formData, userId: user.id },
        isConfigured:
          isValid && (industries.length > 0 || work.formData.industryDisable),
      })
    );
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
  };

  const clickhandler = (target: any) => {
    work.formData[target.id] = target.checked;
    const isValid = isFormValid;
    handleChange(work.formData, isValid);
    setIndustry(target.checked);
  };

  useEffect(() => {
    if (!work.isConfigured && validateForm) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
  }, [Industry, dispatch, validateForm, work.isConfigured]);

  const getIndustries = () => {
    setLoading(true);
    axios.get(`${XANO_API_URL}/industries`).then((response) => {
      const data: any = response.data;
      const options = data.map((item: any) => {
        return item.industryName;
      });
      setIndustryOptions(options);
      setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await getIndustries();
    })();
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
  }, []);

  const onAnyIndustryClick = (target: any) => {
    setIndustryDisabled(target.checked);
    setAnyIndustry(!anyIndustry);
    setIndustryNotsure(false);
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
    dispatch(
      setWork({
        ...work,
        formData: {
          ...work.formData,
          industry: target.checked ? "anyIndustry" : "",
        },
        isConfigured: target.id === "anyIndustry" && target.checked,
      })
    );
  };

  const onNotSureIndustryClick = (target: any) => {
    setIndustries([]);
    setAnyIndustry(false);
    setIndustryNotsure(!industryNotSure);
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
    dispatch(
      setWork({
        ...work,
        formData: {
          ...work.formData,
          industry: target.checked ? "notSure" : "",
        },
        isConfigured: target.id === "notSure" && target.checked,
      })
    );
  };

  const handleDisable = (target: any) => {
    setIndustryDisabled(target.checked);
    dispatch(setDealBreakers({ ...dealBreakers, Industry }));
    const formData = work.formData;
    if (target.checked) {
      setIndustries([]);
      formData.industryDisable = true;
    } else {
      formData.industryDisable = false;
    }
    dispatch(
      setWork({
        ...work,
        formData: {
          ...formData,
          industry: "",
        },
        isConfigured: isFormValid && !industryDisabled,
      })
    );
  };

  return (
    <>
      {loading && <CircularProgress size={32} className="loading" />}
      {!loading && (
        <>
          <Form
            id="work"
            questions={workQuestions}
            handleChange={handleChange}
            formData={work.formData}
          />
          <div className="form-question-title-container">
            <p className="form-question">Which industry do you prefer?</p>
            <div className="form-check">
              <input
                id="Industry"
                className="form-check-input"
                type="checkbox"
                onClick={(e) => {
                  clickhandler(e.target);
                }}
                defaultChecked={Industry}
              />
              <p
                className="form-check-label"
                style={{ color: "#ff0000", fontWeight: "bold" }}
              >
                Non-negotiable
              </p>
            </div>
          </div>
          <div className="work-industry">
            <Multiselect
              className="work-industry-dropdown"
              isObject={false}
              disable={industryNotSure || anyIndustry}
              onSelect={(industries, selectedIndustry) => {
                setIndustries(industries);
                dispatch(
                  setWork({
                    ...work,
                    formData: {
                      ...work.formData,
                      industry: industries.toString(),
                    },
                    isConfigured: isFormValid && industries.length > 0,
                  })
                );
              }}
              selectedValues={industries}
              options={industryOptions}
              placeholder={industries.length > 0 ? "" : "Choose Industry"}
              showCheckbox
              showArrow
              customCloseIcon={
                <CloseIcon
                  sx={{
                    color: red[500],
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                />
              }
              style={{
                chips: {
                  background: "rgba(155, 169, 232, 0.4)",
                  padding: "0.5rem 1rem",
                  color: "#000000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                },
              }}
              singleSelect
            />
          </div>
          <div className="form-input-layout">
            <div className="form-check">
              <input
                id="anyIndustry"
                className="form-check-input"
                type="checkbox"
                defaultChecked={anyIndustry}
                onClick={(e) => {
                  handleDisable(e.target);
                  onAnyIndustryClick(e.target);
                }}
                disabled={industryNotSure}
              />
              <p
                className={`form-check-label ${
                  industryNotSure ? "form-check-label-disabled" : ""
                }`}
              >
                Anything is fine
              </p>
            </div>

            <div className="form-check">
              <input
                id={"notSure"}
                className="form-check-input"
                type="checkbox"
                defaultChecked={industryNotSure}
                onClick={(e) => {
                  handleDisable(e.target);
                  onNotSureIndustryClick(e.target);
                }}
                disabled={anyIndustry}
              />
              <p
                className={`form-check-label ${
                  anyIndustry ? "form-check-label-disabled" : ""
                }`}
              >
                Not Sure
              </p>
            </div>
          </div>
          <p className="form-error-message">{error}</p>
        </>
      )}
    </>
  );
};

export default Work;
