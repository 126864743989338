import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Button from "../Button";
import Accordion from "../Accordion";
import Growth from "../Growth";
import Role from "../Role";
import Salary from "../Salary";
import Perks from "../Perks";
import Work from "../Work";
import Company from "../Company";
import Location from "../Location";
import {
  preferncesSelector,
  growthSelector,
  roleSelector,
  salarySelector,
  perksSelector,
  userSelector,
  workSelector,
  locationSelector,
  companySelector,
  dealBreakersSelector,
} from "../../redux/selectors";
import { HOME } from "../../utils/routes";
import { XANO_API_URL } from "../../utils/api-urls";
import "./styles.scss";

const Review: FC<any> = (props) => {
  const { onChangeOrder } = props;
  const { preferences } = useSelector(preferncesSelector);
  const { growth } = useSelector(growthSelector);
  const { role } = useSelector(roleSelector);
  const { salary } = useSelector(salarySelector);
  const { perks } = useSelector(perksSelector);
  const { work } = useSelector(workSelector);
  const { location } = useSelector(locationSelector);
  const { company } = useSelector(companySelector);
  const { dealBreakers } = useSelector(dealBreakersSelector);
  const { user } = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let hasErrors = false;

  const preferenceHasError = (preference: any) => {
    if (preference.name === "Growth" && !growth.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Role" && !role.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Salary" && !salary.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Perks" && !perks.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Work" && !work.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Location" && !location.isConfigured) {
      hasErrors = true;
      return true;
    }

    if (preference.name === "Company/Brand" && !company.isConfigured) {
      hasErrors = true;
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    const preferencesOrder = preferences.items.map((preference: any) => {
      return preference.name === "Company/Brand" ? "Company" : preference.name;
    });
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/all_prefs`, {
          js: {
            userId: user.id,
            preferencesOrder: preferencesOrder.toString(),
            company: company.formData,
            growth: growth.formData,
            location: location.formData,
            perks: perks.formData,
            role: role.formData,
            salary: salary.formData,
            work: work.formData,
            dealBreakers,
          },
        }),
      ])
      .then(
        axios.spread((resp1) => {
          setLoading(false);
          setIsSubmitted(true);
          const jsPrefUserData = JSON.parse(
            localStorage.getItem("jsPrefUserData")
              ? localStorage.getItem("jsPrefUserData") || "{}"
              : "{}"
          );
          jsPrefUserData.preferencesSet = true;
          localStorage.setItem(
            "jsPrefUserData",
            JSON.stringify(jsPrefUserData)
          );
        })
      )
      .catch((error: any) => {
        console.log(error);
        setError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setLoading(false);
      });
  };

  return (
    <>
      {!isSubmitted && (
        <>
          <div className="revview-order-container">
            <Button
              variant="outlined"
              label="Change Order"
              className="revview-change-order"
              onClick={onChangeOrder}
            />
          </div>
          <div className="review-container">
            <div className="review-text-container">
              <div>
                <p className="review-text">
                  Hi <span style={{ fontWeight: "bold" }}>{user.name}</span>,
                </p>
                <p className="review-text">
                  Here are the choices you have made, you can review them one
                  last time before submitting{" "}
                </p>
              </div>
            </div>
            {preferences.items.map((preference: any) => {
              return (
                <div key={preference.id} className="review-accordion-container">
                  <Accordion
                    title={preference.name}
                    isExpanded={preferenceHasError(preference)}
                    highlightTitle={preferenceHasError(preference)}
                  >
                    {preference.name === "Growth" && (<Growth validateForm />)}
                    {preference.name === "Role" && <Role validateForm />}
                    {preference.name === "Salary" && <Salary validateForm />}
                    {preference.name === "Perks" && <Perks validateForm />}
                    {preference.name === "Work" && <Work validateForm />}
                    {preference.name === "Company/Brand" && (<Company validateForm />)}
                    {preference.name === "Location" && (
                      <Location validateForm />
                    )}
                  </Accordion>
                </div>
              );
            })}
            <div className="review-save-container">
              <div className="review-save-button-container">
                <Button
                  label="Submit"
                  className="review-save"
                  disabled={hasErrors}
                  onClick={handleSubmit}
                  loading={loading}
                />
              </div>
              {hasErrors && (
                <p className="form-error-message">
                  There are one or more forms with errors. Please fix them to
                  proceed with the submission.
                </p>
              )}
              {error && <p className="form-error-message">{error}</p>}
            </div>
          </div>
        </>
      )}
      {isSubmitted && (
        <div className="submission-container">
          <p className="review-text ">
            Hi <span style={{ fontWeight: "bold" }}>{user.name}</span>,
          </p>
          <div className="submission-info-container">
            <InfoIcon sx={{ fontSize: 28 }} />
            <p className="submission-info-container-text">
              Thank you for sharing your preferences with us. <br /> We will get
              back to you shortly with the relevant jobs that fit perfectly with
              your requirements
            </p>
          </div>
          <div className="submission-info-container">
            <VisibilityIcon sx={{ fontSize: 28 }} />
            <p className="submission-info-container-text">STAY TUNED</p>
          </div>
          <div className="submission-info-container">
            <SwapHorizIcon sx={{ fontSize: 28 }} />
            <p className="submission-info-container-text">
              You can come back to this portal at any time and login to change
              your preferences
            </p>
          </div>
          <p className="submission-text">
            We have created an account for you in hiringhood.com
          </p>
          <Button
            className="submission-dashboard"
            label="Got to Dashboard"
            onClick={() => {
              navigate(HOME);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Review;
