import { SET_COMPANY } from "../actions/company";

const INITIAL_STATE = {
  company: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
