import logo from "../../assets/logo-color.svg";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const Header = (props: any) => {
  const { children } = props;
  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles.header}>
        <div className={styles["logo-wrapper"]}>
          <Link to="/">
            <img src={logo} alt="Logo" className={styles.logo}></img>
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Header;
