// Company
export const companyQuestions = [
  {
    question: "What is the size of the company you prefer?",
    hasSingleOption: false,
    nonNegotiable: "companySize",
    any: "anyCompanySize",
    notSure: "notSureCompanySize",
    options: [
      {
        fieldName: "startupsFunded",
        name: "Startup (Funded) (1-10)",
      },
      {
        fieldName: "startupsBootstraped",
        name: "Startup (Bootstraped/Angel Funded) (10-50)",
      },
      {
        fieldName: "midlevelCompany",
        name: "Mid Level Company (50-200)",
      },
      {
        fieldName: "earlyStage",
        name: "Early Stage Scaling Company (200-500)",
      },
      {
        fieldName: "lateStage",
        name: "Late Stage Growth Company (500-1000)",
      },
      {
        fieldName: "mnc",
        name: "MNC (1000+)",
      },
    ],
  },
  {
    question: "What type of companies do you prefer?",
    hasSingleOption: false,
    nonNegotiable: "companyType",
    any: "anyCompany",
    notSure: "notSureCompany",
    options: [
      {
        fieldName: "productBased",
        name: "Product Based",
      },
      {
        fieldName: "serviceBased",
        name: "Service Based",
      },
      {
        fieldName: "hybrid",
        name: "Hybrid",
      },
    ],
  },
  {
    question: "What kind of office do you prefer?",
    hasSingleOption: false,
    nonNegotiable: "companyKind",
    any: "anyOfficeIsFine",
    notSure: "notSure",
    options: [
      {
        fieldName: "hugeOffice",
        name: "Huge Office",
      },
      {
        fieldName: "mediumOffice",
        name: "Medium Office",
      },
      {
        fieldName: "smallOffice",
        name: "Small Office",
      },
      {
        fieldName: "campus",
        name: "Campus",
      },
    ],
  },
];

// Growth
export const growthQuestions = [
  {
    question: "What kind of growth do you prefer?",
    hasSingleOption: false,
    nonNegotiable: "growthKind",
    any: "anyGrowthIsFine",
    notSure: "growthNotSure",
    options: [
      {
        fieldName: "growthInKnownTechnologies",
        name: "Challenging Projects/Work in 'known' technologies",
      },
      {
        fieldName: "growthInNewTechnology",
        name: "Challenging Projects/Work in 'new' technology",
      },
      {
        fieldName: "scopeToLearnNewSkills",
        name: "Scope to learn new skills",
      },
      {
        fieldName: "salaryHike",
        name: "Hike in Salary",
      },
      {
        fieldName: "seniorLevels",
        name: "Promotion to senior levels",
      },
    ],
  },
  {
    question: "Do you expect your company to support learning? and how?",
    hasSingleOption: false,
    nonNegotiable: "growthSupport",
    any: "anyLevelOfSupport",
    notSure: "learningNotSure",
    options: [
      {
        fieldName: "reimburseMyLearningBills",
        name: "Reimburse learning bills",
      },
      {
        fieldName: "taxBenefitsForLearning",
        name: "Provide tax benefits for learning",
      },
      {
        fieldName: "provideLMS",
        name: "Provide LMS (Learning Management System)",
      },
    ],
  },
  {
    question: "How much hike do you expect in each appraisal?",
    fieldName: "hikeInAppraisal",
    hasSlider: true,
    nonNegotiable: "growthHike",
    marks: [
      {
        value: 10,
        label: "10%",
      },
      {
        value: 30,
        label: "30%",
      },
      {
        value: 60,
        label: "60%",
      },
      {
        value: 100,
        label: "100%",
      },
    ],
    min: 10,
    max: 100,
    defaultRange: [20, 40],
    steps: 5,
    unit: "%",
  },
];

// Perks
export const perksQuestions = [
  {
    question: "What kind of perks do you expect?",
    hasSingleOption: false,
    nonNegotiable: "perksKind",
    notSure: "perksNotSure",
    any: "anyPerkIsFine",
    options: [
      {
        fieldName: "foodCoupons",
        name: "Food Coupons",
      },
      {
        fieldName: "internetReimbursement",
        name: "Internet Reimbursement",
      },
      {
        fieldName: "relocationAllowance",
        name: "Relocation Allowance",
      },
      {
        fieldName: "telephoneBillReimbursement",
        name: "Telephone Bill Reimbursement",
      },
      {
        fieldName: "gadgetBillReimbursement",
        name: "Gadget Bill Reimbursement",
      },
      {
        fieldName: "certificationReimbursement",
        name: "Certification Reimbursement",
      },
      {
        fieldName: "booksPeriodicalsReimbursement",
        name: "Books & Periodicals Reimbursement",
      },

      {
        fieldName: "higherEducation",
        name: "Higher Education",
      },
      {
        fieldName: "wellness",
        name: "Wellness",
      },
      {
        fieldName: "transport",
        name: "Transport",
      },
      {
        fieldName: "onsite",
        name: "Onsite",
      },
    ],
  },
  {
    question: "What additional facilities do you expect from the employer?",
    hasSingleOption: false,
    nonNegotiable: "perksAdditionalFacilities",
    notSure: "facilitiesNotSure",
    any: "anyFacilityIsFine",
    options: [
      {
        fieldName: "playPen",
        name: "Play Pen/Creche/Child Care",
      },
      {
        fieldName: "playground",
        name: "Playground",
      },
      {
        fieldName: "basketball",
        name: "Basketball",
      },
      {
        fieldName: "kitchen",
        name: "Kitchen/Canteen",
      },
      {
        fieldName: "gym",
        name: "Gym",
      },
      {
        fieldName: "yoga",
        name: "Yoga/Meditation",
      },
    ],
  },
];

// Role
export const roleQuestions = [
  {
    question: "What kind of role do you prefer?",
    hasSingleOption: true,
    fieldName: "roleType",
    nonNegotiable: "preferredRole",
    options: [
      {
        fieldName: "individualContributor",
        name: "Individual Contributor",
      },
      {
        fieldName: "techLead",
        name: "Technology Leader/Manager",
      },
      {
        fieldName: "peopleManager",
        name: "People Manager",
      },
      {
        fieldName: "functionalLead",
        name: "Functional Lead/Manager",
      },
      {
        fieldName: "anyRoleIsFine",
        name: "Any role is fine",
      },
      {
        fieldName: "notSure",
        name: "Not Sure",
      },
    ],
  },
  {
    question: "What kind of employment do you prefer?",
    hasSingleOption: true,
    fieldName: "employmentType",
    nonNegotiable: "preferredEmployment",
    options: [
      {
        fieldName: "contractor",
        name: "Contractor",
      },
      {
        fieldName: "freelancer",
        name: "Freelancer",
      },
      {
        fieldName: "fullTime",
        name: "Full Time",
      },
      {
        fieldName: "partTime",
        name: "Part Time",
      },
      {
        fieldName: "contractToHire",
        name: "Contract to Hire",
      },
      {
        fieldName: "anyEmploymentIsFine",
        name: "Any employment is fine",
      },
      {
        fieldName: "notSure",
        name: "Not Sure",
      },
    ],
  },
];

// Salary
export const salaryQuestions = [
  {
    question: "What is your expected salary range?",
    fieldName: "salaryRange",
    hasSlider: true,
    nonNegotiable: "salaryRangeDealBreaker",
    marks: [
      {
        value: 1,
        label: "1 LPA",
      },
      {
        value: 30,
        label: "30 LPA",
      },
      {
        value: 60,
        label: "60 LPA",
      },
      {
        value: 100,
        label: "100 LPA",
      },
    ],
    min: 0,
    max: 100,
    defaultRange: [10, 15],
    steps: 1,
    unit: "LPA",
  },
  {
    question: "How much hike do you prefer?",
    fieldName: "salaryHike",
    hasSlider: true,
    nonNegotiable: "salaryHikeDealBreaker",
    marks: [
      {
        value: 10,
        label: "10%",
      },
      {
        value: 30,
        label: "30%",
      },
      {
        value: 60,
        label: "60%",
      },
      {
        value: 100,
        label: "100%",
      },
    ],
    min: 10,
    max: 100,
    defaultRange: [20, 30],
    steps: 10,
    unit: "%",
  },
  {
    question: "Select the salary components you wish to have.",
    hasSingleOption: false,
    nonNegotiable: "salaryComponents",
    any: "anyComponentIsFine",
    notSure: "notSure",
    options: [
      {
        fieldName: "fixedPay",
        name: "Fixed Pay",
      },
      {
        fieldName: "variablePay",
        name: "Variable Pay",
      },
      {
        fieldName: "bonus",
        name: "Bonus",
      },
      {
        fieldName: "esop",
        name: "ESOP",
      },
      {
        fieldName: "joiningBonus",
        name: "Joining Bonus",
      },
    ],
  },
];

// Work
export const workQuestions = [
  {
    question: "What kind of work timing do you prefer?",
    hasSingleOption: false,
    nonNegotiable: "workTime",
    any: "anyTiming",
    notSure: "timingNotSure",
    options: [
      {
        fieldName: "straightHours",
        name: "8 hours straight",
      },
      {
        fieldName: "intermittentWorkWithBreaks",
        name: "Intermittent work with breaks",
      },
      {
        fieldName: "atOwnConvenience",
        name: "At own convenience",
      },
      {
        fieldName: "flexible",
        name: "Flexible",
      },
      {
        fieldName: "taskBased",
        name: "Task Based",
      },
    ],
  },
  {
    question: "Which shift do you prefer to work?",
    hasSingleOption: false,
    nonNegotiable: "workShift",
    any: "anyShift",
    notSure: "shiftNotSure",
    options: [
      {
        fieldName: "dayShift",
        name: "Day Shift",
      },
      {
        fieldName: "nightShift",
        name: "Night Shift",
      },
      {
        fieldName: "afternoonShift",
        name: "Afternoon Shift",
      },
    ],
  },
  {
    question: "What kind of work environment do you prefer?",
    hasSingleOption: false,
    nonNegotiable: "workEnvironment",
    any: "anyKindOfWork",
    notSure: "workNotSure",
    options: [
      {
        fieldName: "challengingWork",
        name: "Challenging Work",
      },
      {
        fieldName: "hardWork",
        name: "Long work hours - hard work",
      },
      {
        fieldName: "relaxedWork",
        name: "Relaxed Work",
      },
      {
        fieldName: "lowStressWork",
        name: "Low stress work",
      },
    ],
  },
  {
    question: "What kind of work excites you?",
    hasSingleOption: false,
    nonNegotiable: "workKind",
    any: "anyKindOfExcitingWork",
    notSure: "excitingWorkNotSure",
    options: [
      {
        fieldName: "rd",
        name: "R & D",
      },
      {
        fieldName: "clientBasedProjects",
        name: "Client based Projects/Products",
      },
      {
        fieldName: "internalProjects",
        name: "Internal Projects/Products",
      },
      {
        fieldName: "maintenanceAndSupport",
        name: "Maintenance and Support",
      },
    ],
  },
];
