import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
import Button from "../Button";
import Input from "../Input";
import { validateEmail } from "../../utils/validators";
import { XANO_API_URL } from "../../utils/api-urls";
import { PREFERENCES } from "../../utils/routes";
import { useLocation } from "react-router-dom";
import "./styles.scss";

const Signup = (props: any) => {
  const search = useLocation().search;
  const emailInput = new URLSearchParams(search).get("email");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(emailInput || "");
  const [otp, setOtp] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEmailvalid, setIsEmailValid] = useState(false);

  const [otpSent, setOtpSent] = useState(false);

  const [waitOnSendOtp, setWaitOnSendOtp] = useState(false);
  const [waitOnSubmit, setWaitOnSubmit] = useState(false);

  const [sendOtpError, setSendOtpError] = useState("");
  const [submitError, setSubmitError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (email && validateEmail(email)) {
      setIsEmailValid(true);
    }
  }, [email]);

  const handleFirstName = (firstName: string) => {
    setFirstName(firstName);
    if (!firstName) {
      setFirstNameError("First Name cannot be empty");
    } else {
      setFirstNameError("");
    }
  };

  const handleLastName = (lastName: string) => {
    setLastName(lastName);
    if (!lastName) {
      setLastNameError("Last Name cannot be empty");
    } else {
      setLastNameError("");
    }
  };

  const handleEmail = (email: string) => {
    setEmail(email);

    if (!email) {
      setEmailError("Email cannot be empty");
      setIsEmailValid(false);
    } else if (!validateEmail(email)) {
      setEmailError("Enter a vaild email address");
      setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);
    }
  };

  const handleOtp = (otp: string) => {
    setOtp(otp);
  };

  const handleSendOTP = async () => {
    setWaitOnSendOtp(true);
    setSendOtpError("");
    try {
      const otpSentResponse: any = await axios.post(
        `${XANO_API_URL}/js_prefs/generate_OTP?newJS=true`,
        {
          email: email,
          subject: "OTP for Registration",
          body: `Dear Jobseeker, <br/><br/> Please use the OTP <b><OTP></b> for your account registration <br/> <br/> Regards,<br> Team Hiringhood`,
        }
      );
      if (otpSentResponse?.data) {
        setOtpSent(true);
        setWaitOnSendOtp(false);
        setSendOtpError("");
      }
    } catch (err: any) {
      setSendOtpError(
        "There was an error while trying to send an OTP to your email address. Please try again later"
      );
      setWaitOnSendOtp(false);
      const { response } = err;
      const { data } = response;
      if (data && data.message === "JS_ALREADY_EXISTS") {
        setSendOtpError(
          `A user already exists with the email address ${email}. Please login to continue`
        );
      } else {
        setSendOtpError(
          "There was an error while trying to send an OTP to your email address. Please try again later"
        );
      }
    }
  };

  const handleSubmit = async () => {
    setWaitOnSubmit(true);
    setSubmitError("");
    try {
      const registerResponse: any = await axios.post(
        `${XANO_API_URL}/js_prefs/register_user`,
        {
          firstName,
          lastName,
          email,
          OTP: otp,
        }
      );
      if (registerResponse?.data) {
        setWaitOnSubmit(false);
        const { data } = registerResponse;
        const jsPrefUserData = {
          id: data["User_ID"],
          name: data["Name"],
          email,
          preferencesSet: false,
        };
        localStorage.setItem("jsPrefUserData", JSON.stringify(jsPrefUserData));
        navigate(PREFERENCES);
      }
    } catch (err: any) {
      const { response } = err;
      const { data } = response;
      if (data && data.message === "USER_ALREADY_EXISTS") {
        setSubmitError(`A user already exists with the email address ${email}`);
      }
      setSubmitError(
        "There was an error during registration. Please try again later"
      );
      setWaitOnSubmit(false);
    }
  };

  return (
    <>
      <div className="signup-text-field-container">
        <Input
          className="signup-input"
          label="First Name*"
          value={firstName}
          onChange={(event) => {
            handleFirstName(event?.target.value);
          }}
          onBlur={(event) => {
            handleFirstName(event?.target.value);
          }}
        />
      </div>
      <div className="signup-text-field-container">
        <Input
          className="signup-input"
          label="Last Name*"
          value={lastName}
          onChange={(event) => {
            handleLastName(event?.target.value);
          }}
          onBlur={(event) => {
            handleLastName(event?.target.value);
          }}
        />
      </div>
      <>
        {!otpSent && (
          <>
            <div className="signup-email-container">
              <Input
                className="signup-email"
                label="Email Address*"
                value={email}
                onChange={(event) => {
                  handleEmail(event.target.value);
                }}
                onBlur={(event) => {
                  handleEmail(event?.target.value);
                }}
                disabled={waitOnSendOtp}
              />
              <div className="signup-send-otp-container">
                <Button
                  className="signup-send-otp"
                  label="Send OTP"
                  disabled={!isEmailvalid || waitOnSendOtp}
                  onClick={handleSendOTP}
                  loading={waitOnSendOtp}
                />
              </div>
            </div>
            <p className="signup-error">{emailError || sendOtpError}</p>
          </>
        )}
      </>
      {otpSent && (
        <>
          <p className="signup-enter-otp">{`Enter the OTP received on your email address: ${email}`}</p>
          <div className="signup-email-container">
            <OtpInput
              shouldAutoFocus
              numInputs={4}
              isInputNum
              inputStyle={"signup-otp-input"}
              onChange={handleOtp}
              value={otp}
            />
          </div>
        </>
      )}
      <div className="signup-submit-container">
        <Button
          label="Submit"
          className="signup-submit"
          disabled={!firstName || !lastName || !(otp.length === 4)}
          onClick={handleSubmit}
          loading={waitOnSubmit}
        />
      </div>
      {submitError && <p className="signup-error">{submitError}</p>}
    </>
  );
};

export default Signup;
