import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../utils/axios";
import { CircularProgress } from "@mui/material";
import Accordion from "../Accordion";
import Button from "../Button";
import Company from "../Company";
import Role from "../Role";
import Growth from "../Growth";
import Work from "../Work";
import Salary from "../Salary";
import Perks from "../Perks";
import Location from "../Location";
import {
  companySelector,
  roleSelector,
  growthSelector,
  workSelector,
  preferncesSelector,
  locationSelector,
  salarySelector,
  perksSelector,
} from "../../redux/selectors";
import { setPreferences } from "../../redux/actions/preferences";
import { XANO_API_URL } from "../../utils/api-urls";
import "./styles.scss";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";

const UpdatePreferences = () => {
  const { company } = useSelector(companySelector);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyError, setCompanyError] = useState("");
  const [companySuccess, setCompanySuccess] = useState("");

  const { role } = useSelector(roleSelector);
  const [roleLoading, setRoleLoading] = useState(false);
  const [roleError, setRoleError] = useState("");
  const [roleSuccess, setRoleSuccess] = useState("");

  const { growth } = useSelector(growthSelector);
  const [growthLoading, setGrowthLoading] = useState(false);
  const [growthError, setGrowthError] = useState("");
  const [growthSuccess, setGrowthSuccess] = useState("");

  const { work } = useSelector(workSelector);
  const [workLoading, setWorkLoading] = useState(false);
  const [workError, setWorkError] = useState("");
  const [workSuccess, setWorkSuccess] = useState("");

  const { location } = useSelector(locationSelector);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [locationSuccess, setLocationSuccess] = useState("");

  const { salary } = useSelector(salarySelector);
  const [salaryLoading, setSalaryLoading] = useState(false);
  const [salaryError, setSalaryError] = useState("");
  const [salarySuccess, setSalarySuccess] = useState("");

  const { perks } = useSelector(perksSelector);
  const [perksLoading, setPerksLoading] = useState(false);
  const [perksError, setPerksError] = useState("");
  const [perksSuccess, setPerksSuccess] = useState("");

  const { preferences } = useSelector(preferncesSelector);

  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const dispatch = useDispatch();

  const jsPrefUserData = JSON.parse(
    localStorage.getItem("jsPrefUserData")
      ? localStorage.getItem("jsPrefUserData") || "{}"
      : "{}"
  );

  useEffect(() => {
    setPreferencesLoading(true);
    axios
      .get(`${XANO_API_URL}/js_prefs/by_js_id?JS_ID=${jsPrefUserData.id}`)
      .then((response) => {
        const { data } = response;
        dispatch(
          setPreferences({
            items: data["preferencesOrder"].split(",").map((item: any) => {
              if (item === "Company") {
                return { name: "Company/Brand" };
              }
              return { name: item };
            }),
          })
        );
        dispatch(
          setCompany({
            formData: {
              ...data.company,
              userId: jsPrefUserData.id,
              companySize: data["dealBreakers"]["companySize"],
              companyType: data["dealBreakers"]["companyType"],
              companyKind: data["dealBreakers"]["companyKind"],
            },
          })
        );
        dispatch(
          setGrowth({
            formData: {
              ...data.growth,
              userId: jsPrefUserData.id,
              growthKind: data["dealBreakers"]["growthKind"],
              growthSupport: data["dealBreakers"]["growthSupport"],
              growthHike: data["dealBreakers"]["growthHike"],
            },
          })
        );
        dispatch(
          setLocation({
            formData: {
              ...data.location,
              userId: jsPrefUserData.id,
              locPreferences: data["dealBreakers"]["locPreferences"],
              locPreferredCities: data["dealBreakers"]["locPreferredCities"],
              locPreferredMode: data["dealBreakers"]["locPreferredMode"],
              locPreferredCountries:
                data["dealBreakers"]["locPreferredCountries"],
            },
          })
        );
        dispatch(
          setPerks({
            formData: {
              ...data.perks,
              userId: jsPrefUserData.id,
              perksKind: data["dealBreakers"]["perksKind"],
              perksAdditionalFacilities:
                data["dealBreakers"]["perksAdditionalFacilities"],
            },
          })
        );
        dispatch(
          setRole({
            formData: {
              ...data.role,
              userId: jsPrefUserData.id,
              preferredRole: data["dealBreakers"]["preferredRole"],
              preferredEmployment: data["dealBreakers"]["preferredEmployment"],
            },
          })
        );
        dispatch(
          setSalary({
            formData: {
              ...data.salary,
              userId: jsPrefUserData.id,
              salaryRangeDealBreaker: data["dealBreakers"]["salaryRange"],
              salaryHikeDealBreaker: data["dealBreakers"]["salaryHike"],
              salaryComponents: data["dealBreakers"]["salaryComponents"],
            },
          })
        );
        dispatch(
          setWork({
            formData: {
              ...data.work,
              userId: jsPrefUserData.id,
              workTime: data["dealBreakers"]["workTime"],
              workShift: data["dealBreakers"]["workShift"],
              workEnvironment: data["dealBreakers"]["workEnvironment"],
              workKind: data["dealBreakers"]["workKind"],
              Industry: data["dealBreakers"]["Industry"],
            },
          })
        );
        setPreferencesLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!company.isConfigured) {
      setCompanyError("");
      setCompanySuccess("");
    }
  }, [company]);

  useEffect(() => {
    if (!role.isConfigured) {
      setRoleError("");
      setRoleSuccess("");
    }
  }, [role]);

  useEffect(() => {
    if (!growth.isConfigured) {
      setGrowthError("");
      setGrowthSuccess("");
    }
  }, [growth]);

  useEffect(() => {
    if (!work.isConfigured) {
      setWorkError("");
      setWorkSuccess("");
    }
  }, [work]);

  useEffect(() => {
    if (!location.isConfigured) {
      setLocationError("");
      setLocationSuccess("");
    }
  }, [location]);

  useEffect(() => {
    if (!salary.isConfigured) {
      setSalaryError("");
      setSalarySuccess("");
    }
  }, [salary]);

  useEffect(() => {
    if (!perks.isConfigured) {
      setPerksError("");
      setPerksSuccess("");
    }
  }, [perks]);

  const handleCompanySubmit = async () => {
    setCompanyLoading(true);
    setCompanyError("");
    setCompanySuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/company`, {
          company: {
            ...company.formData,
          },
          deal_breakers: {
            companySize: company.formData.companySize,
            companyType: company.formData.companyType,
            companyKind: company.formData.companyKind,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setCompanyLoading(false);
          setCompanySuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setCompanyError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setCompanyLoading(false);
      });
  };

  const handleRoleSubmit = async () => {
    setRoleLoading(true);
    setRoleError("");
    setRoleSuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/role`, {
          role: {
            ...role.formData,
          },
          deal_breakers: {
            preferredRole: role.formData.preferredRole,
            preferredEmployment: role.formData.preferredEmployment,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setRoleLoading(false);
          setRoleSuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setRoleError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setRoleLoading(false);
      });
  };

  const handleGrowthSubmit = async () => {
    setGrowthLoading(true);
    setGrowthError("");
    setGrowthSuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/growth`, {
          growth: {
            ...growth.formData,
          },
          deal_breakers: {
            growthKind: growth.formData.growthKind,
            growthSupport: growth.formData.growthSupport,
            growthHike: growth.formData.growthHike,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setGrowthLoading(false);
          setGrowthSuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setGrowthError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setGrowthLoading(false);
      });
  };

  const handleWorkSubmit = async () => {
    setWorkLoading(true);
    setWorkError("");
    setWorkSuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/work`, {
          work: {
            ...work.formData,
          },
          deal_breakers: {
            workTime: work.formData.workTime,
            workShift: work.formData.workShift,
            workEnvironment: work.formData.workEnvironment,
            workKind: work.formData.workKind,
            Industry: work.formData.Industry,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setWorkLoading(false);
          setWorkSuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setWorkError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setWorkLoading(false);
      });
  };

  const handleLocationSubmit = async () => {
    setLocationLoading(true);
    setLocationError("");
    setLocationSuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/location`, {
          location: {
            ...location.formData,
          },
          deal_breakers: {
            locPreferences: location.formData.locPreferences,
            locPreferredCities: location.formData.locPreferredCities,
            locPreferredMode: location.formData.locPreferredMode,
            locPreferredCountries: location.formData.locPreferredCountries,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setLocationLoading(false);
          setLocationSuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setLocationError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setLocationLoading(false);
      });
  };

  const handlePerksSubmit = async () => {
    setPerksLoading(true);
    setPerksError("");
    setPerksSuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/perks`, {
          perks: {
            ...perks.formData,
          },
          deal_breakers: {
            perksKind: perks.formData.perksKind,
            perksAdditionalFacilities: perks.formData.perksAdditionalFacilities,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setPerksLoading(false);
          setPerksSuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setPerksError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setPerksLoading(false);
      });
  };

  const handleSalarySubmit = async () => {
    setSalaryLoading(true);
    setSalaryError("");
    setSalarySuccess("");
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/salary`, {
          salary: {
            ...salary.formData,
          },
          deal_breakers: {
            salaryRange: salary.formData.salaryRangeDealBreaker,
            salaryHike: salary.formData.salaryHikeDealBreaker,
            salaryComponents: salary.formData.salaryComponents,
          },
        }),
      ])
      .then(
        axios.spread((resp1, resp2) => {
          setSalaryLoading(false);
          setSalarySuccess("Your preferences have been successfully saved");
        })
      )
      .catch((error) => {
        setSalaryError(
          "There was an error while submitting your preferences. Please try again later."
        );
        setSalaryLoading(false);
      });
  };

  return (
    <>
      {preferencesLoading && <CircularProgress className="loading" size={32} />}
      {!preferencesLoading && (
        <>
          <p>You can view and edit your preferences here</p>
          <div className="update-preferences-container">
            {preferences.items.map((item: { name: string; id: string }) => {
              if (item.name === "Company/Brand") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Company />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!company.isConfigured}
                          onClick={handleCompanySubmit}
                          loading={companyLoading}
                        />
                      </div>
                      {companyError && (
                        <p className="update-preferences-error">
                          {companyError}
                        </p>
                      )}
                      {companySuccess && (
                        <p className="update-preferences-success">
                          {companySuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Growth") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Growth />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!growth.isConfigured}
                          onClick={handleGrowthSubmit}
                          loading={growthLoading}
                        />
                      </div>
                      {growthError && (
                        <p className="update-preferences-error">
                          {growthError}
                        </p>
                      )}
                      {growthSuccess && (
                        <p className="update-preferences-success">
                          {growthSuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Role") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Role />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!role.isConfigured}
                          onClick={handleRoleSubmit}
                          loading={roleLoading}
                        />
                      </div>
                      {roleError && (
                        <p className="update-preferences-error">{roleError}</p>
                      )}
                      {roleSuccess && (
                        <p className="update-preferences-success">
                          {roleSuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Salary") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Salary />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!salary.isConfigured}
                          onClick={handleSalarySubmit}
                          loading={salaryLoading}
                        />
                      </div>
                      {salaryError && (
                        <p className="update-preferences-error">
                          {salaryError}
                        </p>
                      )}
                      {salarySuccess && (
                        <p className="update-preferences-success">
                          {salarySuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Perks") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Perks />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!perks.isConfigured}
                          onClick={handlePerksSubmit}
                          loading={perksLoading}
                        />
                      </div>
                      {perksError && (
                        <p className="update-preferences-error">{perksError}</p>
                      )}
                      {perksSuccess && (
                        <p className="update-preferences-success">
                          {perksSuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Location") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Location />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!location.isConfigured}
                          onClick={handleLocationSubmit}
                          loading={locationLoading}
                        />
                      </div>
                      {locationError && (
                        <p className="update-preferences-error">
                          {locationError}
                        </p>
                      )}
                      {locationSuccess && (
                        <p className="update-preferences-success">
                          {locationSuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
              if (item.name === "Work") {
                return (
                  <div className="update-preferences-accordion">
                    <Accordion title={item.name}>
                      <Work />
                      <div className="update-preferences-button-container">
                        <Button
                          label="Submit"
                          disabled={!work.isConfigured}
                          onClick={handleWorkSubmit}
                          loading={workLoading}
                        />
                      </div>
                      {workError && (
                        <p className="update-preferences-error">{workError}</p>
                      )}
                      {workSuccess && (
                        <p className="update-preferences-success">
                          {workSuccess}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
    </>
  );
};

export default UpdatePreferences;
