import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircularProgress } from "@mui/material";
import axios from "../../utils/axios";
import Button from "../Button";
import { preferncesSelector, userSelector } from "../../redux/selectors";
import { setPreferences } from "../../redux/actions/preferences";
import { useMediaQuery } from "../../utils/hooks";
import { XANO_API_URL } from "../../utils/api-urls";
import "./styles.scss";

const Order: FC<any> = (props) => {
  const { onSave, hideTitle = false } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const dispatch = useDispatch();

  const { preferences } = useSelector(preferncesSelector);
  const { user } = useSelector(userSelector);
  const matches = useMediaQuery("(min-width:900px)");

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(preferences.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(setPreferences({ ...preferences, items }));
  }

  const handleSave = async () => {
    setError("");
    setLoading(true);
    const preferencesOrder = preferences.items.map((preference: any) => {
      return preference.name === "Company/Brand" ? "Company" : preference.name;
    });
    axios
      .all([
        axios.post(`${XANO_API_URL}/jp_job_seeker_preferences/order`, {
          order: {
            userId: user.id,
            preferences: preferencesOrder.toString(),
          },
        }),
      ])
      .then(
        axios.spread((resp1) => {
          dispatch(
            setPreferences({
              items: preferences.items,
            })
          );
          setLoading(false);
          window.scrollTo(0, 0);
          onSave();
        })
      )
      .catch((error: any) => {
        console.log(error);
        setError(
          "There was an error while saving your preferences. Please try again later."
        );
        setLoading(false);
      });
  };

  return (
    <>
      {preferencesLoading && <CircularProgress className="loading" size={32} />}
      {!preferencesLoading && (
        <div className="order-container">
          {!hideTitle && (
            <p className="order-text">
              Hi <span style={{ fontWeight: "bold" }}> {user.name}</span>,
            </p>
          )}
          <p className="order-text">
            Set the order of preference by dragging the icons below
          </p>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId="preferences"
              direction={matches ? "horizontal" : "vertical"}
            >
              {(provided) => (
                <div
                  className="order-items-container order-items-container2"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {preferences.items.map((item: any, index: number) => {
                    return (
                      <div key={item.id}>
                        <p className="order-index">{index + 1}</p>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="order-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="order-icon-container">
                                <img
                                  className="order-icon"
                                  src={item.image}
                                  alt={item.name}
                                ></img>
                              </div>

                              <p className="order-item-text">{item.name}</p>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="order-button-container">
            <Button
              label="Save and Proceed"
              className="order-submit"
              onClick={handleSave}
              loading={loading}
            />
          </div>
          <p className="form-error-message">{error}</p>
        </div>
      )}
    </>
  );
};

export default Order;
