import { CircularProgress } from "@mui/material";
import "./styles.scss";

type Style = Record<string, unknown>;

/* variant types: 
primary   : Used general as default button
secondary : Used in cases to highlight the button on different background color. 
outlined: : Has a white background with default color border outlined. 
textonly  : Has no outline or background. Its text that acts as button.
option    : Set of buttons act as checkbox i.e. stays in disabled state until clicked again.*/

interface Props {
  label: string;
  variant?: string;
  className?: string;
  style?: Style;
  onClick: (arg0: any) => void;
  disabled?: boolean;
  loading?: boolean;
}

const Button = (props: Props): JSX.Element => {
  const {
    label,
    variant = "primary",
    className = "",
    style,
    onClick,
    disabled = false,
    loading = false,
  } = props;

  const variantMap = new Map();
  variantMap.set("primary", "button-primary");
  variantMap.set("secondary", "button-secondary");
  variantMap.set("outlined", "button-outlined");
  variantMap.set("textonly", "button-textonly");
  variantMap.set("option", "button-option");

  const getVariantClassName = (disabled: boolean) => {
    let variantClassname = variantMap.get(variant);

    if (loading) {
      return `${variantClassname}-disabled`;
    }

    variantClassname =
      disabled === true ? variantClassname + "-disabled" : variantClassname;
    return variantClassname ? variantClassname : "button-primary";
  };

  const handleClick = (event: any) => {
    if (disabled || loading) {
      return;
    }

    onClick(event);
  };

  return (
    <div
      className={`button ${getVariantClassName(disabled)} ${className}`}
      style={style}
      onClick={(event) => {
        handleClick(event);
      }}
    >
      {loading ? <CircularProgress size={22} /> : label}
    </div>
  );
};

export default Button;
