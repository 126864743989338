import { SET_ROLE } from "../actions/role";

const INITIAL_STATE = {
  role: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
