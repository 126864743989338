import { SET_LOCATION } from "../actions/location";

const INITIAL_STATE = {
  location: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
