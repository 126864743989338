import { SET_SALARY } from "../actions/salary";

const INITIAL_STATE = {
  salary: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SALARY:
      return {
        ...state,
        salary: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
