import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../Form";
import { perksSelector, userSelector } from "../../redux/selectors";
import { perksQuestions } from "../../utils/constants";
import { setPerks } from "../../redux/actions/perks";

const Perks: FC<any> = (props) => {
  const { validateForm } = props;
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { perks } = useSelector(perksSelector);
  const { user } = useSelector(userSelector);

  const handleChange = (formData: any, isValid: boolean) => {
    dispatch(
      setPerks({
        ...perks,
        formData: { ...perks.formData, ...formData, userId: user.id },
        isConfigured: isValid,
      })
    );
  };

  useEffect(() => {
    if (!perks.isConfigured && validateForm) {
      setError("You need to select atleast one preference from each section");
    } else {
      setError("");
    }
  }, [perks, validateForm]);

  return (
    <>
      <Form
        id="perks"
        questions={perksQuestions}
        handleChange={handleChange}
        formData={perks.formData}
      />
      <p className="form-error-message">{error}</p>
    </>
  );
};

export default Perks;
