import { SET_DEAL_BREAKERS } from "../actions/dealBreakers";

const INITIAL_STATE = {
  dealBreakers: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DEAL_BREAKERS:
      return {
        ...state,
        dealBreakers: { ...state.dealBreakers, ...action.payload },
      };

    default:
      return state;
  }
};

export default reducer;
