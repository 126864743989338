import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Header from "../../components/Header";
import UpdatePreferences from "../../components/UpdatePreferences";
import { setUserData } from "../../redux/actions/user";
import { userSelector } from "../../redux/selectors";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";
import { HOME } from "../../utils/routes";
import { useMediaQuery } from "../../utils/hooks";
import "./styles.scss";

const menu = [
  { icon: <SettingsIcon />, label: "Preferences" },
  { icon: <LogoutIcon />, label: "Logout" },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(userSelector);
  const matches = useMediaQuery("(min-width:1064px)");

  const [activeStep, setActiveStep] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [orderSaved, setOrderSaved] = useState(false);

  const MobileMenu = () => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setShowDrawer(true);
    };

    return (
      <div>
        <div onClick={handleClick}>
          <MenuIcon sx={{ cursor: "pointer" }} />
        </div>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowDrawer(false);
          }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => {
              setShowDrawer(false);
            }}
            onKeyDown={() => {
              setShowDrawer(false);
            }}
          >
            <p className="menu-title">
              <b>{user.name}</b>
            </p>
            <p className="menu-email">
              <b>{user.email}</b>
            </p>
            {menu.map((item: any, index) => {
              return (
                <MenuItem
                  className={`dashboard-menu  ${
                    item.isDisabled ? "dashboard-menu-disabled" : ""
                  } ${activeStep === index ? "dashboard-active-menu" : ""}`}
                  onClick={() => {
                    handleMenuItemClick(index);
                  }}
                  key={item.label}
                >
                  <div>{item.icon}</div>
                  <div>{item.label}</div>
                </MenuItem>
              );
            })}
          </Box>
        </Drawer>
      </div>
    );
  };

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem("jsPrefUserData")
        ? localStorage.getItem("jsPrefUserData") || "{}"
        : "{}"
    );

    dispatch(setUserData({ ...userData }));
  }, []);

  const handleMenuItemClick = (index: number) => {
    if (index === 1) {
      localStorage.removeItem("jsPrefUserData");
      dispatch(
        setCompany({
          formData: {},
        })
      );
      dispatch(
        setGrowth({
          formData: {},
        })
      );
      dispatch(
        setLocation({
          formData: {},
        })
      );
      dispatch(
        setPerks({
          formData: {},
        })
      );
      dispatch(
        setRole({
          formData: {},
        })
      );
      dispatch(
        setSalary({
          formData: {},
        })
      );
      dispatch(
        setWork({
          formData: {},
        })
      );
      navigate(HOME);
    }
    setOrderSaved(false);
    setActiveStep(index);
  };

  return (
    <>
      <Header>{!matches && <MobileMenu />}</Header>
      <div className="dashboard-container">
        <div style={{ display: "flex" }}>
          {matches && (
            <div className="dashboard-tabs-container">
              {menu.map((item: any, index) => {
                return (
                  <div
                    className={`dashboard-menu ${
                      activeStep === index ? "dashboard-active-menu" : ""
                    } ${item.isDisabled ? "dashboard-menu-disabled" : ""}`}
                    onClick={() => {
                      handleMenuItemClick(index);
                    }}
                    key={item.label}
                  >
                    <div>{item.icon}</div>
                    <div>{item.label}</div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="dashboard-content">
            <p className="dashboard-title">
              Welcome, <b>{user.name}</b>
            </p>
            <div className="dashboard-body">
              {activeStep === 0 && (
                <>
                  <UpdatePreferences />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
