import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Multiselect from "multiselect-react-dropdown";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "../../utils/hooks";
import { locationSelector, userSelector } from "../../redux/selectors";
import { setLocation } from "../../redux/actions/location";
import { LOCATION_TYPES } from "../../utils/site-data";
import office from "../../assets/company.png";
import hybrid from "../../assets/hybrid.png";
import remote from "../../assets/remote-work.png";
import onsite from "../../assets/work.png";
import "./styles.scss";
import { setDealBreakers } from "../../redux/actions/dealBreakers";
import { XANO_API_URL } from "../../utils/api-urls";
import axios from "axios";

const Location: FC<any> = (props) => {
  const { validateForm } = props;
  const matches = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const { location } = useSelector(locationSelector);
  const { user } = useSelector(userSelector);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState<String[]>([]);
  const [cityOptions, setCityOptions] = useState<String[]>([]);

  const locationOptions = [
    {
      id: "hybrid",
      name: "Hybrid",
      image: hybrid,
    },
    {
      id: "remote",
      name: "Remote",
      image: remote,
    },
    {
      id: "office",
      name: "Office",
      image: office,
    },
    {
      id: "onsite",
      name: "Onsite",
      image: onsite,
    },
  ];

  const getCountries = () => {
    setLoading(true);
    axios.get(`${XANO_API_URL}/getAllCountries`).then((response) => {
      const data: any = response.data;
      const options = data.map((item: any) => {
        return item.country;
      });
      setCountryOptions(options);
      setLoading(false);
    });
  };

  const getCitiesForCountries = (countries: any) => {
    setCitiesLoading(true);
    axios
      .get(
        `${XANO_API_URL}/getCitiesByCountries?countries=${countries.join(",")}`
      )
      .then((response) => {
        const data: any = response.data;
        const options = data.map((item: any) => {
          return item.name;
        });
        setCityOptions(["Any city is fine", "Not sure", ...options]);
        setCitiesLoading(false);
      });
  };

  const countries = [
    "Any country is fine",
    "Not sure",
    ...countryOptions.sort(),
  ];

  const [locations, setLocations] = useState<Object[]>(
    location.locationOptions ? location.locationOptions : locationOptions
  );
  const [selectedCities, setSelectedCities] = useState<string[]>(
    location.formData && location.formData.preferredCities
      ? location.formData.preferredCities.split(",")
      : []
  );
  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    location.formData && location.formData.preferredCountries
      ? location.formData.preferredCountries.split(",")
      : []
  );
  const [selectedLocationTypes, setSelectedLocationTypes] = useState<string[]>(
    location.formData && location.formData.preferredMode
      ? location.formData.preferredMode.split(",")
      : []
  );

  const [loading, setLoading] = useState(false);

  const [locPreferredMode, setLocPreferredMode] = useState(
    location.formData ? location.formData.locPreferredMode : false
  );
  const [locPreferences, setLocPreferences] = useState(
    location.formData ? location.formData.locPreferences : false
  );
  const [locPreferredCities, setLocPreferredCities] = useState(
    location.formData ? location.formData.locPreferredCities : false
  );
  const [locPreferredCountries, setLocPreferredCountries] = useState(
    location.formData ? location.formData.locPreferredCountries : false
  );

  const getLocationOptionForPreference = (preference: String) => {
    if (preference === "Hybrid") {
      return {
        id: "hybrid",
        name: "Hybrid",
        image: hybrid,
      };
    } else if (preference === "Remote") {
      return {
        id: "remote",
        name: "Remote",
        image: remote,
      };
    } else if (preference === "Office") {
      return {
        id: "office",
        name: "Office",
        image: office,
      };
    } else if (preference === "Onsite") {
      return {
        id: "onsite",
        name: "Onsite",
        image: onsite,
      };
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (!user.preferencesSet) {
        dispatch(
          setLocation({
            ...location,
            locationOptions,
            isConfigured:
              selectedCities.length > 0 && selectedCountries.length > 0
                ? true
                : false,
            formData: {
              userId: user.id,
              preferences: locationOptions
                .map((item) => {
                  return item.name;
                })
                .toString(),
              preferredCities: selectedCities.toString(),
              preferredCountries: selectedCountries.toString(),
              preferredMode: selectedLocationTypes.toString(),
            },
          })
        );
      } else {
        const { formData } = location;

        const locationOptions = formData.preferences
          .split(",")
          .map((item: String) => {
            return getLocationOptionForPreference(item);
          });

        setLocations([...locationOptions]);

        dispatch(
          setLocation({
            ...location,
            id: formData.id,
            formData: {
              ...formData,
              userId: user.id,
            },
            isConfigured: true,
          })
        );
      }
      setLoading(false);
    };

    fetchData();
    setLocPreferredMode(
      location.formData ? location.formData.locPreferredMode : false
    );
    setLocPreferences(
      location.formData ? location.formData.locPreferences : false
    );
    setLocPreferredCities(
      location.formData ? location.formData.locPreferredCities : false
    );
    setLocPreferredCountries(
      location.formData ? location.formData.locPreferredCountries : false
    );
  }, []);

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  }, []);

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(locations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocations(items);

    dispatch(
      setLocation({
        ...location,
        locationOptions: items,
        formData: {
          ...location.formData,
          preferences: items
            .map((item: any) => {
              return item.name;
            })
            .toString(),
        },
      })
    );
    dispatch(
      setDealBreakers({
        locPreferredMode,
        locPreferences,
        locPreferredCities,
        locPreferredCountries,
      })
    );
  }

  const onCitySelected = (cities: string[], selectedCity: string) => {
    if (selectedCity === "Any city is fine" || selectedCity === "Not sure") {
      const updatedCities = [selectedCity];
      setSelectedCities([...updatedCities]);
      dispatch(
        setLocation({
          ...location,
          isConfigured: selectedCountries.length > 0 ? true : false,
          formData: {
            ...location.formData,
            preferredCities: updatedCities.toString(),
          },
        })
      );
    } else {
      const anyCityIndex = cities.indexOf("Any city is fine");
      const notSureIndex = cities.indexOf("Not sure");
      if (anyCityIndex > -1) {
        cities.splice(anyCityIndex, 1);
      }
      if (notSureIndex > -1) {
        cities.splice(notSureIndex, 1);
      }
      setSelectedCities([...cities]);
      dispatch(
        setLocation({
          ...location,
          isConfigured: selectedCountries.length > 0 ? true : false,
          formData: {
            ...location.formData,
            preferredCities: cities.toString(),
          },
        })
      );
      dispatch(
        setDealBreakers({
          locPreferredMode,
          locPreferences,
          locPreferredCities,
          locPreferredCountries,
        })
      );
    }
  };

  const onCountrySelected = (countries: string[], selectedCountry: string) => {
    if (
      selectedCountry === "Any country is fine" ||
      selectedCountry === "Not sure"
    ) {
      const updatedCountries = [selectedCountry];
      setSelectedCountries([...updatedCountries]);
      dispatch(
        setLocation({
          ...location,
          isConfigured: selectedCities.length > 0 ? true : false,
          formData: {
            ...location.formData,
            preferredCountries: updatedCountries.toString(),
          },
        })
      );
      dispatch(
        setDealBreakers({
          locPreferredMode,
          locPreferences,
          locPreferredCities,
          locPreferredCountries,
        })
      );
    } else {
      const anyCountryIndex = countries.indexOf("Any country is fine");
      const notSureIndex = countries.indexOf("Not sure");
      if (anyCountryIndex > -1) {
        countries.splice(anyCountryIndex, 1);
      }
      if (notSureIndex > -1) {
        countries.splice(notSureIndex, 1);
      }
      setSelectedCountries([...countries]);
      dispatch(
        setLocation({
          ...location,
          isConfigured: selectedCities.length > 0 ? true : false,
          formData: {
            ...location.formData,
            preferredCountries: countries.toString(),
          },
        })
      );
    }
  };

  const onLocationTypeSelected = (
    locationTypes: string[],
    selectedLocation: string
  ) => {
    setSelectedLocationTypes([...locationTypes]);
    dispatch(
      setLocation({
        ...location,
        isConfigured: true,
        formData: {
          ...location.formData,
          preferredMode: locationTypes.toString(),
        },
      })
    );
    dispatch(
      setDealBreakers({
        locPreferredMode,
        locPreferences,
        locPreferredCities,
        locPreferredCountries,
      })
    );
  };

  return (
    <>
      {loading && <CircularProgress size={32} className="loading" />}
      {!loading && (
        <div className="location-container">
          <p>
            <span className="text-big">
              <strong>
                Set the order of preference for the mode of location by dragging
                the icons below
              </strong>
            </span>
          </p>
          <div className="form-deal-breaker">
            <input
              type="checkbox"
              checked={locPreferredMode}
              className="form-check-input"
              onClick={() => {
                dispatch(
                  setLocation({
                    ...location,
                    formData: {
                      ...location.formData,
                      locPreferredMode: !locPreferredMode,
                    },
                  })
                );
                setLocPreferredMode(!locPreferredMode);
                dispatch(
                  setDealBreakers({
                    locPreferredMode,
                    locPreferences,
                    locPreferredCities,
                    locPreferredCountries,
                  })
                );
              }}
            />
            <label>Non-negotiable</label>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId="preferences"
              direction={matches ? "horizontal" : "vertical"}
            >
              {(provided) => (
                <div
                  className="location-items-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {locations.map((item: any, index: number) => {
                    return (
                      <div key={item.id}>
                        <p className="location-index">{index + 1}</p>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="location-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="location-icon-container">
                                <img
                                  className="location-icon"
                                  src={item.image}
                                  alt={item.name}
                                ></img>
                              </div>
                              <p className="location-item-text">{item.name}</p>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="location-deal-breaker">
            <p className="location-text">
              <span className="text-big">
                <strong>In which location do you prefer the job?</strong>
              </span>
            </p>
            <div className="form-deal-breaker">
              <input
                type="checkbox"
                checked={locPreferences}
                className="form-check-input"
                onClick={() => {
                  dispatch(
                    setLocation({
                      ...location,
                      formData: {
                        ...location.formData,
                        locPreferences: !locPreferences,
                      },
                    })
                  );
                  setLocPreferences(!locPreferences);
                  dispatch(
                    setDealBreakers({
                      locPreferredMode,
                      locPreferences,
                      locPreferredCities,
                      locPreferredCountries,
                    })
                  );
                }}
              />
              <label>Non-negotiable</label>
            </div>
          </div>
          <Multiselect
            isObject={false}
            onSelect={(locationTypes, selectedLocationType) => {
              onLocationTypeSelected(locationTypes, selectedLocationType);
            }}
            onRemove={(locationTypes, removedLocationType) => {
              setSelectedLocationTypes([...locationTypes]);
              dispatch(
                setLocation({
                  ...location,
                  isConfigured: locationTypes.length > 0 ? true : false,
                  formData: {
                    ...location.formData,
                    prerredLocationTypes: locationTypes.toString(),
                  },
                })
              );
            }}
            selectedValues={selectedLocationTypes}
            options={LOCATION_TYPES}
            placeholder={
              selectedLocationTypes.length > 0 ? "" : "Choose a location type"
            }
            showCheckbox
            showArrow
            customCloseIcon={
              <CloseIcon
                sx={{
                  color: red[500],
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                }}
              />
            }
            style={{
              chips: {
                background: "rgba(155, 169, 232, 0.4)",
                padding: "0.5rem 1rem",
                color: "#000000",
                fontSize: "1rem",
                fontWeight: "bold",
              },
            }}
          />
          <div className="location-deal-breaker">
            <p className="location-text">
              <span className="text-big">
                <strong>Which countries do you prefer?</strong>
              </span>
            </p>
            <div className="form-deal-breaker">
              <input
                type="checkbox"
                checked={locPreferredCountries}
                className="form-check-input"
                onClick={() => {
                  dispatch(
                    setLocation({
                      ...location,
                      formData: {
                        ...location.formData,
                        locPreferredCountries: !locPreferredCountries,
                      },
                    })
                  );
                  setLocPreferredCountries(!locPreferredCountries);
                  dispatch(
                    setDealBreakers({
                      locPreferredMode,
                      locPreferences,
                      locPreferredCities,
                      locPreferredCountries,
                    })
                  );
                }}
              />
              <label>Non-negotiable</label>
            </div>
          </div>
          <Multiselect
            isObject={false}
            onSelect={(countries, selectedCountry) => {
              onCountrySelected(countries, selectedCountry);
              getCitiesForCountries(countries);
            }}
            onRemove={(countries, removedCountry) => {
              setSelectedCountries([...countries]);
              dispatch(
                setLocation({
                  ...location,
                  isConfigured:
                    countries.length > 0 && selectedCities.length > 0
                      ? true
                      : false,
                  formData: {
                    ...location.formData,
                    preferredCountries: countries.toString(),
                    preferredCities: "",
                  },
                })
              );
              getCitiesForCountries(countries);
            }}
            selectedValues={selectedCountries}
            options={countries}
            placeholder={selectedCountries.length > 0 ? "" : "Choose Country"}
            showCheckbox
            showArrow
            customCloseIcon={
              <CloseIcon
                sx={{
                  color: red[500],
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                }}
              />
            }
            style={{
              chips: {
                background: "rgba(155, 169, 232, 0.4)",
                padding: "0.5rem 1rem",
                color: "#000000",
                fontSize: "1rem",
                fontWeight: "bold",
              },
            }}
          />
          <div className="location-deal-breaker">
            <p className="location-text">
              <span className="text-big">
                <strong>Which cities do you prefer?</strong>
              </span>
            </p>
            <div className="form-deal-breaker">
              <input
                type="checkbox"
                checked={locPreferredCities}
                className="form-check-input"
                onClick={() => {
                  dispatch(
                    setLocation({
                      ...location,
                      formData: {
                        ...location.formData,
                        locPreferredCities: !locPreferredCities,
                      },
                    })
                  );
                  setLocPreferredCities(!locPreferredCities);
                  dispatch(
                    setDealBreakers({
                      locPreferredMode,
                      locPreferences,
                      locPreferredCities,
                      locPreferredCountries,
                    })
                  );
                }}
              />
              <label>Non-negotiable</label>
            </div>
          </div>
          <Multiselect
            isObject={false}
            onSelect={(cities, selectedCity) => {
              onCitySelected(cities, selectedCity);
            }}
            onRemove={(cities, removedCity) => {
              setSelectedCities([...cities]);
              dispatch(
                setLocation({
                  ...location,
                  isConfigured:
                    cities.length > 0 && selectedCountries.length > 0
                      ? true
                      : false,
                  formData: {
                    ...location.formData,
                    preferredCities: cities.toString(),
                  },
                })
              );
            }}
            selectedValues={selectedCities}
            options={cityOptions}
            placeholder={selectedCities.length > 0 ? "" : "Choose City"}
            showCheckbox
            showArrow
            customCloseIcon={
              <CloseIcon
                sx={{
                  color: red[500],
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                }}
              />
            }
            style={{
              chips: {
                background: "rgba(155, 169, 232, 0.4)",
                padding: "0.5rem 1rem",
                color: "#000000",
                fontSize: "1rem",
                fontWeight: "bold",
              },
            }}
            disable={selectedCountries.length === 0}
          />
          {validateForm &&
            (selectedCities.length === 0 || selectedCountries.length === 0) && (
              <p className="form-error-message">
                You need to select at least one preference for city and country
              </p>
            )}
        </div>
      )}
    </>
  );
};

export default Location;
