export const EMAIL_REGEX = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,8})+$/;

export function validateOtp(otp: string) {
  const regex = /^[0-9][0-9][0-9][0-9]/;
  return regex.test(otp.toLowerCase());
}

export const validateEmail = (email: string) => {
  return EMAIL_REGEX.test(email.toLowerCase());
};
