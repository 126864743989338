import Button from "../../components/Button";
import notFoundImage from "../../assets/not-found.svg";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../utils/routes";
import "./styles.scss";

const Error = () => {
  const navigate = useNavigate();

  return (
    <div className="error-wrapper">
      <div className="error-wrapper-container">
        <div className="error-container">
          <img src={notFoundImage} alt="Not Found" className="error-image" />
          <div>
            <p className="error-title">404 error</p>
            <p className="error-description">Page not found!</p>
            <Button
              label="Home"
              className="error-button"
              onClick={() => {
                navigate(HOME);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
