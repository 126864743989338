import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PREFERENCES } from "../../utils/routes";
import background from "../../assets/background.svg";
import logo from "../../assets/logo.svg";
import Signup from "../../components/Signup";
import Login from "../../components/Login";
import "./styles.scss";

const HomePage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const onRegister = () => {
    setActiveTab(0);
  };

  const onLogin = () => {
    setActiveTab(1);
  };

  useEffect(() => {
    const jsPrefUserData = JSON.parse(
      localStorage.getItem("jsPrefUserData")
        ? localStorage.getItem("jsPrefUserData") || "{}"
        : "{}"
    );

    localStorage.setItem("jsPrefUserData", JSON.stringify(jsPrefUserData));

    if (jsPrefUserData.id && jsPrefUserData.name) {
      navigate(PREFERENCES);
    }
  }, [navigate]);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <img src={logo} alt="Logo" className="home-page-logo" />
      <div className="home-page-container">
        <div className="home-page-tabs-container">
          <p
            className={`home-page-tab ${
              activeTab === 0 ? "home-page-active-tab" : ""
            }`}
            onClick={() => {
              setActiveTab(0);
            }}
          >
            Register
          </p>
          <p
            className={`home-page-tab ${
              activeTab === 1 ? "home-page-active-tab" : ""
            }`}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Login
          </p>
        </div>
        {activeTab === 0 && <Signup onLogin={onLogin} />}
        {activeTab === 1 && <Login onRegister={onRegister} />}
      </div>
    </div>
  );
};

export default HomePage;
