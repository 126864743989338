import { SET_GROWTH } from "../actions/growth";

const INITIAL_STATE = {
  growth: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_GROWTH:
      return {
        ...state,
        growth: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
