import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import Slider from "../Slider";
import { userSelector, dealBreakersSelector } from "../../redux/selectors";
import { useMediaQuery } from "../../utils/hooks";
import "./styles.css";
import { setDealBreakers } from "../../redux/actions/dealBreakers";

const Form: FC<any> = (props) => {
  const { id, questions = [], handleChange, formData = {} } = props;
  const { user } = useSelector(userSelector);
  const { dealBreakers } = useSelector(dealBreakersSelector);
  const largeDevice = useMediaQuery("(min-width:880px)");
  const mediumDevice = useMediaQuery("(min-width:672px)");

  const getWidth = () => {
    if (largeDevice) {
      return 700;
    } else if (mediumDevice) {
      return 550;
    }

    return 240;
  };

  const isFormValid = () => {
    let isValid = false;
    for (let i = 0; i < questions.length; i++) {
      isValid = false;
      const item = questions[i];
      const any = formData[item.any];
      const notSure = formData[item.notSure];

      if (item.options && item.options.length > 0) {
        const fields = item.options.map((option: any) => {
          return option.fieldName;
        });

        if (any) {
          isValid = true;
          continue;
        }

        if (notSure) {
          isValid = true;
          continue;
        }

        for (let j = 0; j < fields.length; j++) {
          if (formData[fields[j]]) {
            isValid = true;
            break;
          } else {
            isValid = false;
          }
        }

        if (item.hasSingleOption && formData[item.fieldName]) {
          isValid = true;
          continue;
        }
      }

      if (item.hasSlider) {
        isValid = true;
        continue;
      }

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  useEffect(() => {
    for (let i = 0; i < questions.length; i++) {
      const item = questions[i];
      if (item.hasSlider) {
        const value = item.defaultRange;
        if (value[0] === value[1]) {
          formData[item.fieldName] = `${value[0]}${item.unit}`;
        } else {
          formData[
            item.fieldName
          ] = `${value[0]}${item.unit} - ${value[1]}${item.unit}`;
        }
      }
    }
    const isValid = isFormValid();
    handleChange({ ...formData, userId: user.id }, isValid);
  }, [id]);

  const clickhandler = (target: any) => {
    formData[target.id] = target.checked;
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const nonNegotiableHandler = (target: any, nonNegotiableField: any) => {
    formData[target.id] = target.checked;
    // TODO Need to be handled in a better way inside the Salary component
    if (nonNegotiableField === "salaryRangeDealBreaker") {
      nonNegotiableField = "salaryRange";
    }
    if (nonNegotiableField === "salaryHikeDealBreaker") {
      nonNegotiableField = "salaryHike";
    }
    if (target.checked) {
      dealBreakers[nonNegotiableField] = true;
    } else {
      dealBreakers[nonNegotiableField] = false;
    }
    setDealBreakers({ ...dealBreakers });
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const radioHandler = (target: any, item: any) => {
    formData[target.id] = target.value;
    formData[item.any] = false;
    formData[item.notSure] = false;
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const disableHandler = (index: number, target: any) => {
    formData[target.id] = target.checked;
    if (target.checked) {
      if (!questions[index].hasSlider && !questions[index].hasSingleOption) {
        questions[index].options.forEach((item: any) => {
          formData[item.fieldName] = false;
          const isValid = isFormValid();
          handleChange(formData, isValid);
        });
      }
      formData[questions[index].fieldName] = "";
    }
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const anyHandler = (index: number, target: any) => {
    if (target.checked) {
      formData[questions[index].any] = true;
      formData[questions[index].notSure] = false;
      if (!questions[index].hasSlider && !questions[index].hasSingleOption) {
        questions[index].options.forEach((item: any) => {
          formData[item.fieldName] = false;
        });
      }
    }
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const notSureHandler = (index: number, target: any) => {
    if (target.checked) {
      formData[questions[index].notSure] = true;
      formData[questions[index].any] = false;
      if (!questions[index].hasSlider && !questions[index].hasSingleOption) {
        questions[index].options.forEach((item: any) => {
          formData[item.fieldName] = false;
        });
      }
    }
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  const getRangeFromFormData = (item: any) => {
    if (formData[item.fieldName]) {
      const range = formData[item.fieldName];
      const updatedRange = range.replaceAll(item.unit, "");
      const startValue = updatedRange.split("-")[0].trim();
      const endValue = updatedRange.split("-")[1]
        ? updatedRange.split("-")[1].trim()
        : updatedRange.split("-")[0].trim();

      return [startValue, endValue];
    }

    return item.defaultRange;
  };

  const sliderHandler = (value: any, item: any) => {
    if (value[0] === value[1]) {
      formData[
        item.fieldName
      ] = `${value[0]}${item.unit} - ${value[0]}${item.unit}`;
    } else {
      formData[
        item.fieldName
      ] = `${value[0]}${item.unit} - ${value[1]}${item.unit}`;
    }
    const isValid = isFormValid();
    handleChange(formData, isValid);
  };

  return (
    <>
      {questions.map((item: any, index: number) => {
        return (
          <>
            <div className="form-question-title-container">
              <p className="form-question">{item.question}</p>
              <div className="form-check">
                <input
                  id={item.nonNegotiable}
                  className="form-check-input"
                  type="checkbox"
                  onClick={(e) => {
                    nonNegotiableHandler(e.target, item.nonNegotiable);
                  }}
                  defaultChecked={formData[item.nonNegotiable]}
                />
                <p
                  className="form-check-label"
                  style={{ color: "#ff0000", fontWeight: "bold" }}
                >
                  Non-negotiable
                </p>
              </div>
            </div>
            <div className="form-input-layout">
              {!item.hasSingleOption &&
                !item.hasSlider &&
                item.options.map((option: any) => {
                  return (
                    <div className="form-check">
                      <input
                        id={option.fieldName}
                        className="form-check-input"
                        type="checkbox"
                        checked={
                          formData[item.any] || formData[item.notSure]
                            ? false
                            : formData[option.fieldName]
                        }
                        onClick={(e) => {
                          clickhandler(e.target);
                        }}
                        disabled={formData[item.any] || formData[item.notSure]}
                      />
                      <p
                        className={`form-check-label ${
                          formData[item.any] || formData[item.notSure]
                            ? "form-check-label-disabled"
                            : ""
                        }`}
                      >
                        {option.name}
                      </p>
                    </div>
                  );
                })}
              {item.hasSingleOption &&
                !item.hasSlider &&
                item.options.map((option: any) => {
                  return (
                    <div className="form-check">
                      <input
                        id={item.fieldName}
                        className="form-check-input"
                        type="radio"
                        value={option.fieldName}
                        checked={formData[item.fieldName] === option.fieldName}
                        onClick={(e) => {
                          radioHandler(e.target, item);
                        }}
                        disabled={formData[item.disableField]}
                      />
                      <p
                        className={`form-check-label ${
                          formData[item.disableField]
                            ? "form-check-label-disabled"
                            : ""
                        }`}
                      >
                        {option.name}
                      </p>
                    </div>
                  );
                })}
              {!item.hasSlider && !item.hasSingleOption && (
                <>
                  <div className="form-check">
                    <input
                      id={item.any}
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={formData[item.any]}
                      onClick={(e) => {
                        disableHandler(index, e.target);
                        anyHandler(index, e.target);
                      }}
                      disabled={formData[item.notSure]}
                    />
                    <p
                      className={`form-check-label ${
                        formData[item.notSure]
                          ? "form-check-label-disabled"
                          : ""
                      }`}
                    >
                      Anything is fine
                    </p>
                  </div>

                  <div className="form-check">
                    <input
                      id={item.notSure}
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={formData[item.notSure]}
                      onClick={(e) => {
                        disableHandler(index, e.target);
                        notSureHandler(index, e.target);
                      }}
                      disabled={formData[item.any]}
                    />
                    <p
                      className={`form-check-label ${
                        formData[item.any] ? "form-check-label-disabled" : ""
                      }`}
                    >
                      Not Sure
                    </p>
                  </div>
                </>
              )}
              {item.hasSlider && (
                <div className="form-slider">
                  <Slider
                    marks={item.marks}
                    min={item.min}
                    max={item.max}
                    step={item.steps}
                    width={getWidth()}
                    sliderValue={getRangeFromFormData(item)}
                    onSliderChange={(value) => {
                      sliderHandler(value, item);
                    }}
                  />
                </div>
              )}
            </div>
          </>
        );
      })}
    </>
  );
};

export default Form;
