import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PreferencesPage from "./pages/Preferences";
import HomePage from "./pages/Home";
import ErrorPage from "./pages/Error";
import DashboardPage from "./pages/Dashboard";
import * as routes from "./utils/routes";

function App() {
  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path={routes.HOME} element={<HomePage />} />
          <Route path={routes.PREFERENCES} element={<PreferencesPage />} />
          <Route path={routes.DASHBOARD} element={<DashboardPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
