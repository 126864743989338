import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "../Form";
import { salarySelector, userSelector } from "../../redux/selectors";
import { salaryQuestions } from "../../utils/constants";
import { setSalary } from "../../redux/actions/salary";

const Salary: FC<any> = (props) => {
  const { validateForm } = props;
  const { salary } = useSelector(salarySelector);
  const { user } = useSelector(userSelector);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleChange = (formData: any, isValid: boolean) => {
    dispatch(
      setSalary({
        ...salary,
        formData: { ...salary.formData, ...formData, userId: user.id },
        isConfigured: isValid,
      })
    );
  };

  useEffect(() => {
    if (!salary.isConfigured && validateForm) {
      setError(
        "You need to select at least one preference for the salary component"
      );
    } else {
      setError("");
    }
  }, [salary.isConfigured, validateForm]);

  return (
    <>
      {/* <p className="form-title">Salary</p> */}
      <Form
        id="salary"
        questions={salaryQuestions}
        handleChange={handleChange}
        formData={salary.formData}
      />
      <p className="form-error-message">{error}</p>
    </>
  );
};

export default Salary;
