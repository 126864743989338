import React from "react";
import "./styles.scss";

interface Props {
  id?: string;
  placeholder?: string;
  type?: string;
  value: string;
  label?: string;
  className?: string;
  onChange: React.ChangeEventHandler<any>;
  onBlur?: React.ChangeEventHandler<any>;
  onTextAreaChange?: any;
  ontextAreaBlur?: any;
  disabled?: boolean;
  autoFocus?: boolean;
  rows?: number;
}

const Input = (props: Props): JSX.Element => {
  const {
    id,
    placeholder,
    type,
    value,
    label,
    className = "",
    onChange,
    onBlur = () => {},
    disabled = false,
    autoFocus = false,
    rows,
  } = props;

  return (
    <div className="input-container">
      <label htmlFor={id} className={value ? "input-active-label" : ""}>
        {label}
      </label>
      {!rows && (
        <input
          type={type}
          value={value}
          onChange={(event) => {
            onChange(event);
          }}
          onBlur={(event) => {
            onBlur(event);
          }}
          id={id}
          name={id}
          className={`input-text ${className}`}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
      {rows && (
        <textarea
          value={value}
          onChange={(event) => {
            onChange(event);
          }}
          onBlur={(event) => {
            onBlur(event);
          }}
          id={id}
          name={id}
          className={`input-text ${className}`}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          rows={rows}
        />
      )}
    </div>
  );
};

export default Input;
