import { useState, FC } from "react";
import { useSelector } from "react-redux";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import Button from "../Button";
import Growth from "../Growth";
import Role from "../Role";
import Salary from "../Salary";
import Perks from "../Perks";
import Company from "../Company";
import Work from "../Work";
import Location from "../Location";
import { preferncesSelector } from "../../redux/selectors";
import { useMediaQuery } from "../../utils/hooks";
import "./styles.scss";

const Preferences: FC<any> = (props) => {
  const { onChangeOrder, onFinish } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { preferences } = useSelector(preferncesSelector);
  const matches = useMediaQuery("(min-width:952px)");

  const handlePrevious = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleNext = () => {
    if (activeTab <= preferences.items.length - 2) {
      setActiveTab(activeTab + 1);
    } else {
      onFinish();
    }
  };

  return (
    <>
      <div className="preferences-order-container">
        <Button
          variant="outlined"
          label="Change Order"
          className="preferences-change-order"
          onClick={onChangeOrder}
        />
      </div>
      <div className="preferences-container">
        {matches && (
          <div className="preferences-tabs-container">
            {preferences.items.map((item: any, index: number) => {
              return (
                <div
                  className={`preferences-tabs-tab ${
                    activeTab === index ? "preferences-tabs-active-tab" : ""
                  }`}
                  key={item.id}
                >
                  <div
                    className={`preferences-tabs-text ${
                      activeTab === index ? "preferences-tabs-active-text" : ""
                    }`}
                    onClick={() => {
                      setActiveTab(index);
                    }}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      event.keyCode === 13 && setActiveTab(index);
                    }}
                  >
                    <div className="preferences-tab-container">
                      <img
                        className="preferences-tab-image"
                        src={item.image}
                        alt="icon"
                      />
                      {item.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="preferences-form-container">
          {!matches && (
            <div className="preferences-form-navigation-buttons-container">
              <BackIcon
                className="preferences-form-navigation-icon"
                color={activeTab === 0 ? "disabled" : "action"}
                onClick={handlePrevious}
              />
              <NextIcon
                className="preferences-form-navigation-icon"
                color="action"
                onClick={handleNext}
              />
            </div>
          )}

          {!matches && (
            <p className="preferences-tabs-title">
              {preferences.items[activeTab].name}
            </p>
          )}
          {preferences.items[activeTab].name === "Growth" && <Growth />}
          {preferences.items[activeTab].name === "Role" && <Role />}
          {preferences.items[activeTab].name === "Salary" && <Salary />}
          {preferences.items[activeTab].name === "Perks" && <Perks />}
          {preferences.items[activeTab].name === "Company/Brand" && <Company />}
          {preferences.items[activeTab].name === "Work" && <Work />}
          {preferences.items[activeTab].name === "Location" && <Location />}
        </div>
        <div className="preferences-button-container">
          <div className="preferences-button-wrapper">
            <Button
              label="Previous"
              disabled={activeTab === 0}
              onClick={handlePrevious}
            />
            <Button
              label={activeTab === preferences.length - 1 ? "Finish" : "Next"}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
