import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
import DoneIcon from "@mui/icons-material/Done";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { validateEmail } from "../../utils/validators";
import { XANO_API_URL } from "../../utils/api-urls";
import { PREFERENCES } from "../../utils/routes";
import "./styles.scss";

const Login = (props: any) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const [emailError, setEmailError] = useState("");
  const [isEmailvalid, setIsEmailValid] = useState(false);

  const [otpSent, setOtpSent] = useState(false);
  const [disableVerify, setDisableVerify] = useState(true);
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [waitOnSendOtp, setWaitOnSendOtp] = useState(false);
  const [waitOnVerifyOtp, setWaitOnVerifyOtp] = useState(false);

  const [sendOtpError, setSendOtpError] = useState("");
  const [verifyOtpError, setVerifyOtpError] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleEmail = (email: string) => {
    setEmail(email);

    if (!email) {
      setEmailError("Email cannot be empty");
      setIsEmailValid(false);
    } else if (!validateEmail(email)) {
      setEmailError("Enter a vaild email address");
      setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);
    }
  };

  const handleOtp = (otp: string) => {
    setOtp(otp);
    if (otp.length === 4) {
      setDisableVerify(false);
    } else {
      setDisableVerify(true);
    }
  };

  const handleSendOTP = async () => {
    setWaitOnSendOtp(true);
    setSendOtpError("");
    try {
      const otpSentResponse: any = await axios.post(
        `${XANO_API_URL}/js_prefs/generate_OTP?newJS=false`,
        {
          email: email,
          subject: "OTP for Login",
          body: `Dear Jobseeker, <br/><br/> Please use the OTP <b><OTP></b> for login <br/> <br/> Regards,<br> Team Hiringhood`,
        }
      );
      if (otpSentResponse?.data) {
        setOtpSent(true);
        setWaitOnSendOtp(false);
        setSendOtpError("");
      }
    } catch (err: any) {
      setWaitOnSendOtp(false);
      const { response } = err;
      const { data } = response;
      if (data && data.message === "JS_IS_NOT_REGISTERED") {
        setSendOtpError(
          `The email address ${email} is not registered with us. Please register before proceeding to login`
        );
      } else {
        setSendOtpError(
          "There was an error while trying to send an OTP to your email address. Please try again later"
        );
      }
    }
  };

  const handleVerifyOTP = async () => {
    setVerifyOtpError("");
    setWaitOnVerifyOtp(true);
    try {
      const verifyResponse: any = await axios.post(
        `${XANO_API_URL}/js_prefs/login_user`,
        {
          email,
          OTP: otp,
        }
      );
      if (verifyResponse?.data) {
        const { data } = verifyResponse;
        setIsOtpValid(true);
        setWaitOnVerifyOtp(false);
        setVerifyOtpError("");
        const jsPrefUserData = {
          id: data["User_ID"],
          name: data["Name"],
          email: data["Email_ID"],
          preferencesSet: data["all_prefs_added"],
        };
        localStorage.setItem("jsPrefUserData", JSON.stringify(jsPrefUserData));
        navigate(PREFERENCES);
      }
    } catch (err: any) {
      const { response } = err;
      const { data } = response;
      if (data && data.message === "USER_NOT_REGISTERED") {
        setVerifyOtpError(
          `The email address ${email} is not registered with us. Please register before proceeding to login`
        );
      } else {
        setVerifyOtpError(
          "There was an error during login. Please try again later"
        );
      }
      setIsOtpValid(false);
      setWaitOnVerifyOtp(false);
    }
  };

  useEffect(() => {
    const jsPrefUserData = JSON.parse(
      localStorage.getItem("jsPrefUserData")
        ? localStorage.getItem("jsPrefUserData") || "{}"
        : "{}"
    );

    if (jsPrefUserData.id && jsPrefUserData.name) {
      navigate(PREFERENCES);
    }
  }, []);

  return (
    <>
      <>
        {!otpSent && (
          <>
            <div className="login-email-container">
              <Input
                className="login-email"
                label="Email Address*"
                value={email}
                onChange={(event) => {
                  handleEmail(event?.target.value);
                }}
                onBlur={(event) => {
                  handleEmail(event?.target.value);
                }}
                disabled={waitOnSendOtp}
              />
              <div className="login-send-otp-container">
                <Button
                  className="login-send-otp"
                  label="Send OTP"
                  disabled={!isEmailvalid || waitOnSendOtp}
                  onClick={handleSendOTP}
                  loading={waitOnSendOtp}
                />
              </div>
            </div>
            <p className="login-error">{emailError || sendOtpError}</p>
          </>
        )}
      </>
      {otpSent && !isOtpValid && (
        <>
          <p className="login-enter-otp">{`Enter the OTP received on your email address: ${email}`}</p>
          <div className="login-email-container">
            <OtpInput
              shouldAutoFocus
              numInputs={4}
              isInputNum
              inputStyle={"login-otp-input"}
              onChange={handleOtp}
              value={otp}
              isDisabled={waitOnVerifyOtp}
            />
            <div className="login-verify-otp-container">
              <Button
                label="Submit"
                className="login-verify-otp"
                onClick={handleVerifyOTP}
                disabled={disableVerify || waitOnVerifyOtp}
                loading={waitOnVerifyOtp}
              />
            </div>
          </div>
          {verifyOtpError && <p className="login-error">{verifyOtpError}</p>}
        </>
      )}
      {isOtpValid && (
        <>
          {`Email : ${email}`}{" "}
          <DoneIcon
            color="success"
            style={{ marginLeft: "0.5rem", fontWeight: "bold" }}
          />
        </>
      )}
      {error && <p className="login-error">{error}</p>}
    </>
  );
};

export default Login;
