import perks from "../assets/perks.png";
import growth from "../assets/growth.png";
import location from "../assets/location.png";
import company from "../assets/company.png";
import salary from "../assets/salary.png";
import role from "../assets/role.png";
import work from "../assets/work.png";

export const OTP_REGISTER_MESSAGE =
  "Dear Job Seeker,\n\n Please use the OTP : ${otp} to verify your email address. The OTP is valid for 1 minute\n\n - Team Hiringhood";

export const OTP_LOGIN_MESSAGE =
  "Dear Job Seeker,\n\n Please use the OTP : ${otp} for login. The OTP is valid for 1 minute\n\n - Team Hiringhood";

export const JOB_SEEKER_PREFERENCES = [
  {
    id: "company",
    name: "Company/Brand",
    image: company,
  },
  {
    id: "growth",
    name: "Growth",
    image: growth,
  },
  {
    id: "location",
    name: "Location",
    image: location,
  },
  {
    id: "perks",
    name: "Perks",
    image: perks,
  },
  {
    id: "role",
    name: "Role",
    image: role,
  },
  {
    id: "salary",
    name: "Salary",
    image: salary,
  },
  {
    id: "work",
    name: "Work",
    image: work,
  },
];

export const getPreferenceObject = (name: string) => {
  if (name === "Company") {
    return {
      id: "company",
      name: "Company/Brand",
      image: company,
    };
  } else if (name === "Growth") {
    return {
      id: "growth",
      name: "Growth",
      image: growth,
    };
  } else if (name === "Location") {
    return { id: "location", name: "Location", image: location };
  } else if (name === "Perks") {
    return {
      id: "perks",
      name: "Perks",
      image: perks,
    };
  } else if (name === "Role") {
    return {
      id: "role",
      name: "Role",
      image: role,
    };
  } else if (name === "Salary") {
    return {
      id: "salary",
      name: "Salary",
      image: salary,
    };
  } else if (name === "Work") {
    return {
      id: "work",
      name: "Work",
      image: work,
    };
  }
};

export const CITIES = [
  "Abohar ",
  "Achalpur ",
  "Adilabad ",
  "Adityapur ",
  "Adoni ",
  "Agartala ",
  "Agra ",
  "Ahmadabad ",
  "Ahmadnagar ",
  "Aizawl ",
  "Ajmer ",
  "Akbarpur ",
  "Akola ",
  "Alandur ",
  "Alappuzha ",
  "Aligarh ",
  "Allahabad ",
  "Alwar ",
  "Ambala ",
  "Ambala Sadar ",
  "Ambarnath ",
  "Ambattur ",
  "Ambikapur ",
  "Ambur ",
  "Amravati ",
  "Amreli ",
  "Amritsar ",
  "Amroha ",
  "Anand ",
  "Anantapur ",
  "Anantnag ",
  "Arrah ",
  "Asansol ",
  "Ashoknagar Kalyangarh ",
  "Aurangabad ",
  "Aurangabad ",
  "Avadi ",
  "Azamgarh ",
  "Badlapur ",
  "Bagaha ",
  "Bagalkot ",
  "Bahadurgarh ",
  "Baharampur ",
  "Bahraich ",
  "Baidyabati ",
  "Baleshwar Town ",
  "Ballia ",
  "Bally ",
  "Bally City",
  "Balurghat ",
  "Banda ",
  "Bankura ",
  "Bansberia ",
  "Banswara ",
  "Baran ",
  "Baranagar ",
  "Barasat ",
  "Baraut ",
  "Barddhaman ",
  "Bareilly ",
  "Baripada Town ",
  "Barnala ",
  "Barrackpur ",
  "Barshi ",
  "Basirhat ",
  "Basti ",
  "Batala ",
  "Bathinda ",
  "Beawar ",
  "Begusarai ",
  "Belgaum ",
  "Bellary ",
  "Bengaluru",
  "Bettiah ",
  "Betul ",
  "Bhadrak ",
  "Bhadravati ",
  "Bhadreswar ",
  "Bhagalpur ",
  "Bhalswa Jahangir Pur ",
  "Bharatpur ",
  "Bharuch ",
  "Bhatpara ",
  "Bhavnagar ",
  "Bhilai Nagar ",
  "Bhilwara ",
  "Bhimavaram ",
  "Bhind ",
  "Bhiwadi ",
  "Bhiwandi ",
  "Bhiwani ",
  "Bhopal ",
  "Bhubaneswar Town ",
  "Bhuj ",
  "Bhusawal ",
  "Bid ",
  "Bidar ",
  "Bidhan Nagar ",
  "Biharsharif ",
  "Bijapur ",
  "Bikaner ",
  "Bilaspur ",
  "Bokaro Steel City ",
  "Bongaon ",
  "Botad ",
  "Brahmapur Town ",
  "Budaun ",
  "Bulandshahr ",
  "Bundi ",
  "Burari ",
  "Burhanpur ",
  "Buxar ",
  "Champdani ",
  "Chandannagar ",
  "Chandausi ",
  "Chandigarh ",
  "Chandrapur ",
  "Chapra ",
  "Chas ",
  "Chennai ",
  "Chhattarpur ",
  "Chhindwara ",
  "Chikmagalur ",
  "Chilakaluripet ",
  "Chitradurga ",
  "Chittaurgarh ",
  "Chittoor ",
  "Churu ",
  "Coimbatore ",
  "Cuddalore ",
  "Cuttack ",
  "Dabgram ",
  "Dallo Pura ",
  "Damoh ",
  "Darbhanga ",
  "Darjiling ",
  "Datia ",
  "Davanagere ",
  "Deesa ",
  "Dehradun ",
  "Dehri ",
  "Delhi ",
  "Delhi Cantonment ",
  "Deoghar ",
  "Deoli ",
  "Deoria ",
  "Dewas ",
  "Dhanbad ",
  "Dharmavaram ",
  "Dhaulpur ",
  "Dhule ",
  "Dibrugarh ",
  "Dimapur ",
  "Dinapur Nizamat ",
  "Dindigul ",
  "Dum Dum ",
  "Durg ",
  "Durgapur ",
  "Eluru ",
  "English Bazar ",
  "Erode ",
  "Etah ",
  "Etawah ",
  "Faizabad ",
  "Faridabad ",
  "Farrukhabad-cum-Fatehgarh ",
  "Fatehpur ",
  "Firozabad ",
  "Firozpur ",
  "Gadag-Betigeri ",
  "Gandhidham ",
  "Gandhinagar ",
  "Ganganagar ",
  "Gangapur City ",
  "Gangawati ",
  "Gaya ",
  "Ghazipur ",
  "Giridih ",
  "Godhra ",
  "Gokal Pur ",
  "Gonda ",
  "Gondal ",
  "Gondiya ",
  "Gorakhpur ",
  "Greater Hyderabad ",
  "Greater Mumbai ",
  "Greater Noida ",
  "Gudivada ",
  "Gulbarga ",
  "Guna ",
  "Guntakal ",
  "Guntur ",
  "Gurgaon ",
  "Guwahati ",
  "Gwalior ",
  "Habra ",
  "Hajipur ",
  "Haldia ",
  "Haldwani-cum-Kathgodam ",
  "Halisahar ",
  "Hanumangarh ",
  "Haora ",
  "Hapur ",
  "Hardoi ",
  "Hardwar ",
  "Hassan ",
  "Hastsal ",
  "Hathras ",
  "Hazaribag ",
  "Hindaun ",
  "Hindupur ",
  "Hinganghat ",
  "Hisar ",
  "Hoshangabad ",
  "Hoshiarpur ",
  "Hospet ",
  "Hosur ",
  "Hubli-Dharwad ",
  "Hugli-Chinsurah ",
  "Ichalkaranji ",
  "Imphal ",
  "Indore ",
  "Jabalpur ",
  "Jagadhri ",
  "Jagdalpur ",
  "Jaipur ",
  "Jalandhar ",
  "Jalgaon ",
  "Jalna ",
  "Jalpaiguri ",
  "Jamalpur ",
  "Jammu ",
  "Jamnagar ",
  "Jamshedpur ",
  "Jamuria ",
  "Jaunpur ",
  "Jehanabad ",
  "Jetpur Navagadh ",
  "Jhansi ",
  "Jhunjhunun ",
  "Jind ",
  "Jodhpur ",
  "Junagadh ",
  "Kadapa ",
  "Kaithal ",
  "Kakinada ",
  "Kalol ",
  "Kalyani ",
  "Kamarhati ",
  "Kancheepuram ",
  "Kanchrapara ",
  "Kanpur ",
  "Kanpur City",
  "Karaikkudi ",
  "Karawal Nagar ",
  "Karimnagar ",
  "Karnal ",
  "Kasganj ",
  "Kashipur ",
  "Katihar ",
  "Khammam ",
  "Khandwa ",
  "Khanna ",
  "Kharagpur ",
  "Khardaha ",
  "Khargone ",
  "Khora ",
  "Khurja ",
  "Kirari Suleman Nagar ",
  "Kishanganj ",
  "Kishangarh ",
  "Kochi ",
  "Kolar ",
  "Kolhapur ",
  "Kolkata ",
  "Kollam ",
  "Korba ",
  "Kota ",
  "Kozhikode ",
  "Krishnanagar ",
  "Kulti ",
  "Kumbakonam ",
  "Kurichi ",
  "Kurnool ",
  "Lakhimpur ",
  "Lalitpur ",
  "Latur ",
  "Loni ",
  "Lucknow ",
  "Ludhiana ",
  "Machilipatnam ",
  "Madanapalle ",
  "Madavaram ",
  "Madhyamgram ",
  "Madurai ",
  "Mahbubnagar ",
  "Mahesana ",
  "Maheshtala ",
  "Mainpuri ",
  "Malegaon ",
  "Malerkotla ",
  "Mandoli ",
  "Mandsaur ",
  "Mandya ",
  "Mangalore ",
  "Mango ",
  "Mathura ",
  "Maunath Bhanjan ",
  "Medinipur ",
  "Meerut ",
  "Mira Bhayander ",
  "Miryalaguda ",
  "Mirzapur-cum-Vindhyachal ",
  "Modinagar ",
  "Moga ",
  "Moradabad ",
  "Morena ",
  "Morvi ",
  "Motihari ",
  "Mughalsarai ",
  "Muktsar ",
  "Munger ",
  "Murwara ",
  "Mustafabad ",
  "Muzaffarnagar ",
  "Muzaffarpur ",
  "Mysore ",
  "Nabadwip ",
  "Nadiad ",
  "Nagaon ",
  "Nagapattinam ",
  "Nagaur ",
  "Nagda ",
  "Nagercoil ",
  "Nagpur ",
  "Naihati ",
  "Nalgonda ",
  "Nanded Waghala ",
  "Nandurbar ",
  "Nandyal ",
  "Nangloi Jat ",
  "Narasaraopet ",
  "Nashik ",
  "Navi Mumbai ",
  "Navi Mumbai Panvel Raigarh ",
  "Navsari ",
  "Neemuch ",
  "Nellore ",
  "New Delhi ",
  "Neyveli ",
  "Nizamabad ",
  "Noida ",
  "North Barrackpur ",
  "North Dum Dum ",
  "Ongole ",
  "Orai ",
  "Osmanabad ",
  "Ozhukarai ",
  "Palakkad ",
  "Palanpur ",
  "Pali ",
  "Pallavaram ",
  "Palwal ",
  "Panchkula ",
  "Panihati ",
  "Panipat ",
  "Panvel ",
  "Parbhani ",
  "Patan ",
  "Pathankot ",
  "Patiala ",
  "Patna ",
  "Pilibhit ",
  "Pimpri Chinchwad ",
  "Pithampur ",
  "Porbandar ",
  "Port Blair ",
  "Proddatur ",
  "Puducherry ",
  "Pudukkottai ",
  "Pune ",
  "Puri ",
  "Purnia ",
  "Puruliya ",
  "Rae Bareli ",
  "Raichur ",
  "Raiganj ",
  "Raigarh ",
  "Raipur ",
  "Rajahmundry ",
  "Rajapalayam ",
  "Rajarhat Gopalpur ",
  "Rajkot ",
  "Rajnandgaon ",
  "Rajpur Sonarpur ",
  "Ramagundam ",
  "Rampur ",
  "Ranchi ",
  "Ranibennur ",
  "Raniganj ",
  "Ratlam ",
  "Raurkela Industrial Township ",
  "Raurkela Town ",
  "Rewa ",
  "Rewari ",
  "Rishra ",
  "Robertson Pet ",
  "Rohtak ",
  "Roorkee ",
  "Rudrapur ",
  "S.A.S. Nagar ",
  "Sagar ",
  "Saharanpur ",
  "Saharsa ",
  "Salem ",
  "Sambalpur ",
  "Sambhal ",
  "Sangli Miraj Kupwad ",
  "Santipur ",
  "Sasaram ",
  "Satara ",
  "Satna ",
  "Sawai Madhopur ",
  "Secunderabad ",
  "Sehore ",
  "Seoni ",
  "Serampore ",
  "Shahjahanpur ",
  "Shamli ",
  "Shikohabad ",
  "Shillong ",
  "Shimla ",
  "Shimoga ",
  "Shivpuri ",
  "Sikar ",
  "Silchar ",
  "Siliguri ",
  "Singrauli ",
  "Sirsa ",
  "Sitapur ",
  "Siwan ",
  "Solapur ",
  "Sonipat ",
  "South Dum Dum ",
  "Srikakulam ",
  "Srinagar ",
  "Sujangarh ",
  "Sultan Pur Majra ",
  "Sultanpur ",
  "Surat ",
  "Surendranagar Dudhrej ",
  "Suryapet ",
  "Tadepalligudem ",
  "Tadpatri ",
  "Tambaram ",
  "Tenali ",
  "Thane ",
  "Thanesar ",
  "Thanjavur ",
  "Thiruvananthapuram ",
  "Thoothukkudi ",
  "Thrissur ",
  "Tiruchirappalli ",
  "Tirunelveli ",
  "Tirupati ",
  "Tiruppur ",
  "Tiruvannamalai ",
  "Tiruvottiyur ",
  "Titagarh ",
  "Tonk ",
  "Tumkur ",
  "Udaipur ",
  "Udgir ",
  "Udupi ",
  "Ujjain ",
  "Ulhasnagar ",
  "Uluberia ",
  "Unnao ",
  "Uttarpara Kotrung ",
  "Vadodara ",
  "Valsad ",
  "Varanasi ",
  "Vasai Virar City ",
  "Vellore ",
  "Veraval ",
  "Vidisha ",
  "Vijayawada ",
  "Visakhapatnam",
  "Vizianagaram ",
  "Warangal ",
  "Wardha ",
  "Yamunanagar ",
  "Yavatmal ",
];

export const COUNTRIES = [
  "Andorra",
  "United Arab Emirates",
  "Afghanistan",
  "Antigua and Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Netherlands Antilles",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Azerbaijan",
  "Bosnia and Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "Bermuda",
  "Brunei",
  "Bolivia",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos [Keeling] Islands",
  "Congo [DRC]",
  "Central African Republic",
  "Congo [Republic]",
  "Switzerland",
  "Côte d'Ivoire",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Cape Verde",
  "Christmas Island",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands [Islas Malvinas]",
  "Micronesia",
  "Faroe Islands",
  "France",
  "Gabon",
  "United Kingdom",
  "Grenada",
  "Georgia",
  "French Guiana",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia",
  "Guinea",
  "Guadeloupe",
  "Equatorial Guinea",
  "Greece",
  "South Georgia and the South Sandwich Islands",
  "Guatemala",
  "Guam",
  "Guinea-Bissau",
  "Guyana",
  "Gaza Strip",
  "Hong Kong",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran",
  "Iceland",
  "Italy",
  "Jersey",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Comoros",
  "Saint Kitts and Nevis",
  "North Korea",
  "South Korea",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Laos",
  "Lebanon",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Moldova",
  "Montenegro",
  "Madagascar",
  "Marshall Islands",
  "Macedonia [FYROM]",
  "Mali",
  "Myanmar [Burma]",
  "Mongolia",
  "Macau",
  "Northern Mariana Islands",
  "Martinique",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "French Polynesia",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "Saint Pierre and Miquelon",
  "Pitcairn Islands",
  "Puerto Rico",
  "Palestinian Territories",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Réunion",
  "Romania",
  "Serbia",
  "Russia",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "Saint Helena",
  "Slovenia",
  "Svalbard and Jan Mayen",
  "Slovakia",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "São Tomé and Príncipe",
  "El Salvador",
  "Syria",
  "Swaziland",
  "Turks and Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Timor-Leste",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "Turkey",
  "Trinidad and Tobago",
  "Tuvalu",
  "Taiwan",
  "Tanzania",
  "Ukraine",
  "Uganda",
  "U.S. Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vatican City",
  "Saint Vincent and the Grenadines",
  "Venezuela",
  "British Virgin Islands",
  "U.S. Virgin Islands",
  "Vietnam",
  "Vanuatu",
  "Wallis and Futuna",
  "Samoa",
  "Kosovo",
  "Yemen",
  "Mayotte",
  "South Africa",
  "Zambia",
  "Zimbabwe",
];

export const LOCATION_TYPES = [
  "Metro",
  "Small Town",
  "Tier 1 City",
  "Tier 2 City",
];

export const INDUSTRIES = [
  "IT/Computers - Software",
  "Banking/Accounting/Financial Services",
  "IT/Computers - Hardware & Networking",
  "Internet/E-commerce",
  "Recruitment/Staffing/RPO",
  "Telecom",
  "ITES/BPO",
  "Semiconductor",
  "Electronics Manufacturing",
  "Automotive/Automobile/Ancillaries",
  "Hospitals/Healthcare/Diagnostics",
  "Pharmaceutical",
  "Retailing",
  "Consulting/Advisory Services",
  "Oil/Gas/Petroleum",
  "Courier/Freight/Transportation",
  "Insurance",
  "Hotels/Hospitality/Restaurant",
  "Education",
  "Engineering & Design",
  "Entertainment/Media/Publishing",
  "Advertising/PR/Events",
  "Construction & Engineering",
  "Real Estate",
  "Chemicals/Petrochemicals",
  "Airlines/Aviation/Aerospace",
  "KPO/Research/Analytics",
  "Food & Packaged Food",
  "Travel/Tourism",
  "Bio Technology & Life Sciences",
  "FMCG",
  "Consumer Electronics/Durables/Appliances",
  "Machinery/Equipment Mfg",
  "Engineering/Procurement/Construction",
  "Power/Energy",
  "Mining",
  "Fashion/Apparels",
  "Market Research",
  "E-Learning",
  "Social Media",
  "Architecture/Interior Design",
  "Agriculture/Dairy/Forestry/Fishing",
  "Animation",
  "Government/PSU/Defence",
  "Semiconductor Manufacturing",
];
