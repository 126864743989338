import { useState } from "react";
import { withStyles, makeStyles, createStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";

const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      height: 3,
    },
  })
);

const PrettoSlider = withStyles({
  root: {
    color: "#4D6CD9",
    height: 16,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: ".125rem solid currentColor",
    marginTop: -1,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    "&.MuiSlider-thumb": {
      marginLeft: "-0.1rem",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% )",
  },
  track: {
    height: 16,
    borderRadius: 8,
    width: "100%",
  },
  rail: {
    height: 16,
    borderRadius: 8,
  },
  mark: {
    backgroundColor: "#DFE5FF",
    height: 8,
    width: 0,
    marginTop: 0,
    marginRight: 0,
  },
  markLabel: {
    marginTop: "0.5rem",
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.375rem",
  },
})(Slider);

interface Mark {
  value: number;
  label: string;
}

interface Props {
  marks: Mark[];
  defaultValue?: number;
  step: number;
  min: number;
  max: number;
  width?: number;
  sliderValue: any;
  onSliderChange: (arg0: any) => void;
  disabled?: boolean;
}

const CustomizedSlider = (props: Props): JSX.Element => {
  const classes = useStyles(props);

  const {
    marks,
    defaultValue,
    step,
    min,
    max,
    width = 400,
    sliderValue,
    onSliderChange,
    disabled = false,
  } = props;

  const [value, setValue] = useState(sliderValue);

  const ChangeHandler = (event: any) => {
    setValue(event.target.value);
    onSliderChange(event.target.value);
  };

  return (
    <>
      <div className={classes.margin} />
      <PrettoSlider
        valueLabelDisplay="auto"
        defaultValue={defaultValue}
        step={step}
        marks={marks}
        min={min}
        max={max}
        style={{ width: width }}
        value={value}
        onChange={ChangeHandler}
        disabled={disabled}
      />
    </>
  );
};

export default CustomizedSlider;
