import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import Header from "../../components/Header";
import Order from "../../components/Order";
import EditPreferences from "../../components/Preferences";
import Review from "../../components/Review";
import { setUserData } from "../../redux/actions/user";
import { DASHBOARD, HOME } from "../../utils/routes";
import { setCompany } from "../../redux/actions/company";
import { setGrowth } from "../../redux/actions/growth";
import { setLocation } from "../../redux/actions/location";
import { setPerks } from "../../redux/actions/perks";
import { setRole } from "../../redux/actions/role";
import { setSalary } from "../../redux/actions/salary";
import { setWork } from "../../redux/actions/work";
import "./styles.scss";

const Preferences = () => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onOrderSaved = () => {
    setActiveStep(1);
  };

  const onChangeOrder = () => {
    setActiveStep(0);
  };

  const onFinish = () => {
    setActiveStep(2);
  };

  const steps = [
    {
      icon: FormatListNumberedIcon,
      text: "Select Order",
    },
    {
      icon: SettingsIcon,
      text: "Give Preferences",
    },
    {
      icon: InfoIcon,
      text: "Review",
    },
  ];

  useEffect(() => {
    const jsPrefUserData = JSON.parse(
      localStorage.getItem("jsPrefUserData")
        ? localStorage.getItem("jsPrefUserData") || "{}"
        : "{}"
    );

    dispatch(setUserData({ ...jsPrefUserData }));

    if (!jsPrefUserData.id || !jsPrefUserData.name) {
      navigate(HOME);
    }

    if (jsPrefUserData.preferencesSet) {
      navigate(DASHBOARD);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("jsPrefUserData");
    dispatch(
      setCompany({
        formData: {},
      })
    );
    dispatch(
      setGrowth({
        formData: {},
      })
    );
    dispatch(
      setLocation({
        formData: {},
      })
    );
    dispatch(
      setPerks({
        formData: {},
      })
    );
    dispatch(
      setRole({
        formData: {},
      })
    );
    dispatch(
      setSalary({
        formData: {},
      })
    );
    dispatch(
      setWork({
        formData: {},
      })
    );
    navigate(HOME);
  };

  return (
    <>
      <Header />
      <div className="preferences-container">
        <p className="preferences-title">Job Seeker Preferences</p>
        <div
          className="preferences-logout"
          title="Logout"
          onClick={handleLogout}
        >
          <LogoutIcon />
        </div>
        <div className="preferences-steps-container">
          {steps.map((step, index) => {
            return (
              <div className="preferences-step-container" key={step.text}>
                <step.icon
                  sx={{ fontSize: 30 }}
                  className="preferences-step-icon"
                  color={activeStep === index ? "primary" : "disabled"}
                />
                <p
                  className={`preferences-step-text ${
                    activeStep === index
                      ? "preferences-step-active"
                      : "preferences-step-disabled"
                  }`}
                >
                  {step.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="preferences-content-container">
          <div className="preferences-content">
            {activeStep === 0 && <Order onSave={onOrderSaved} />}
            {activeStep === 1 && (
              <EditPreferences
                onChangeOrder={onChangeOrder}
                onFinish={onFinish}
              />
            )}
            {activeStep === 2 && <Review onChangeOrder={onChangeOrder} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
